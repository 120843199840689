<div class="col-12 banner">
    <app-slider [images]="banners" *ngIf="banners.length > 0"></app-slider>
</div>
<div class="thankyou-page">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-12">
                <h3 class="primary text-center text-bold">
                    Thank You!<br>{{getmessage(type).head}}

                </h3>
                <p class="text-center">
                    {{getmessage(type).mess}}
                </p>
                <div class="thankyou-img text-center">
                    <span class="icon-thumb"></span>
                </div>
                <a href="/"><p class="text-center">
                    Back to Home
                </p></a>
            </div>
        </div>
       
    </div>
</div>
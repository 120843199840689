<div class="compare" *ngIf="Vehicles">
    <div *ngIf="!loading" class="col-12 banner">
        <img loading="lazy" src="{{series.BannerFileURL}}" alt="">
    </div>
    <div class="row head">
        <div class="col-4 d-none d-md-block">
            <p>Model</p>
        </div>
        <div [ngClass]="sortasc == 0?'selected':''" class="col-12 col-md-2" (click)="pricesort(0)">
            <p>Price</p>
        </div>
        <div [ngClass]="sortasc == 1?'selected':''" class="col-12 col-md-2" (click)="pricesort(1)">
            <p>Transmission</p>
        </div>
        <div [ngClass]="sortasc == 2?'selected':''" class="col-12 col-md-2" (click)="pricesort(2)">
            <p>Fuel Type</p>
        </div>
        <div [ngClass]="sortasc == 3?'selected':''" class="col-12 col-md-2" (click)="pricesort(3)">
            <p>Engine Size</p>
        </div>
    </div>
    <div class="row vehicle" *ngFor="let v of Vehicles">
        <div class="vehcimg col-12 col-md-4">
            <img loading="lazy" *ngIf="v.Images!==null" class="col-6" src="{{imgadd(v.Images[0])}}" alt="">
            <img loading="lazy" *ngIf="v.Images==null" class="col-6" src="assets/placeholder.webp" alt="">
            <div class=" col-12 col-md-10">
                <h6>{{v.VehicleMake}} {{v.VehicleSeries}}</h6>
                <h5>{{v.Name}}</h5>
            </div>
        </div>
        <div class="col-12 col-md-2 price">
            <p class="d-md-none">Price</p>
            <h4 *ngIf="v.NewVehiclePrice">{{this.currency}}{{v.NewVehiclePrice | number:'1.0-0'}}</h4>
            <h4 *ngIf="!v.NewVehiclePrice">POA</h4>
        </div>
        <div class="col-12 col-md-2">
            <p class="d-md-none">Transmission</p>
            <h4>{{v.VehicleTransmission}}</h4>
        </div>
        <div class="col-12 col-md-2">
            <p class="d-md-none">Fuel</p>
            <h4>{{v.FuelType}}</h4>
        </div>
        <div class="col-12 col-md-2">
            <p class="d-md-none">Engine Capacity</p>
            <h4>{{v.CapacityCC}}</h4>
        </div>
    </div>

    <div class="bottom">
        <button class="btn-secondary" (click)="back()">
            Back to {{Vehicles[0].VehicleMake}} vehicles</button>
    </div>
</div>
<div *ngIf="currentSite">
    <div *ngFor="let pagepart of currentSite.layout">
        <div *ngIf="pagepart.PageLayoutModuleName == 'home'">
            <div *ngFor="let pagepartType of pagepart.PageLayoutType">
                <div *ngIf="pagepartType.PageLayoutTypeName == 'main'">
                    <div class="main row">
                        <div *ngFor="let mainitem of pagepartType.Value" [ngClass]="homeCardClass">
                            <div *ngIf="mainitem.name == 'buy'" class="card">
                                <a href="/buy"><img fetchpriority="high" class="card-img-top" [src]="mainitem.image"
                                        alt="" aria-label="Buy"></a>
                                <div class="card-body" *ngIf="!Truck">
                                    <p>{{mainitem.text}}</p>
                                    <div class="search">
                                        <select (change)="goBuy(buyMake.value)" aria-label="buy" class="col-10"
                                            #buyMake >
                                            <option value="null" selected hidden>Select Make</option>
                                            <option *ngFor="let make of this.Makes" value="{{make.VehicleMakeID_PK}}">
                                                {{make.Name.toUpperCase()}}</option>
                                        </select>
                                        <button (click)="goBuy(buyMake.value)" class="col-2 btn-primary"
                                            title="Buy Make Search" type="button"><span
                                                class="icon-search"></span></button>
                                    </div>

                                </div>
                                <div class="card-body" *ngIf="Truck">
                                    <p>{{mainitem.text}}</p>
                                    <div class="search">
                                        <select (change)="goBuy(buyMake.value)" aria-label="buy" class="col-10"
                                            #buyMake>
                                            <option value="null" selected hidden>Select Range</option>
                                            <option *ngFor="let range of this.Range" value="{{range.url}}">
                                                {{range.text}}</option>
                                        </select>
                                        <button (click)="goBuy(buyMake.value)" class="col-2 btn-primary"
                                            title="Buy Make Search" type="button"><span
                                                class="icon-search"></span></button>
                                    </div>

                                </div>
                            </div>

                            <div *ngIf="mainitem.name == 'buySeries'" class="card">
                                <a href="/buy"><img fetchpriority="high" class="card-img-top" [src]="mainitem.image"
                                        alt="" aria-label="Buy"></a>
                                <div class="card-body">
                                    <p>{{mainitem.text}}</p>
                                    <div class="search">
                                        <select (change)="goBuy(buySeries.value, true)" aria-label="buy" class="col-10"
                                            #buySeries >
                                            <option value="null" selected hidden>Select Series</option>
                                            <option *ngFor="let series of this.Series" value="{{series.VehicleSeriesID_PK}}">
                                                {{series.Name.toUpperCase()}}</option>
                                        </select>
                                        <button (click)="goBuy(buySeries.value)" class="col-2 btn-primary"
                                            title="Buy Make Search" type="button"><span
                                                class="icon-search"></span></button>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="mainitem.name == 'sell'" class="card">
                                <a href="/sell"><img fetchpriority="high" class="card-img-top" [src]="mainitem.image"
                                        alt="" aria-label="Sell"></a>
                                <div class="card-body">
                                    <p>{{mainitem.text}}</p>
                                    <div class="search">
                                        <select (change)="goSell(sellMake.value)" aria-label="sell" class="col-10"
                                            #sellMake>
                                            <option value="null" selected hidden>Select Make</option>
                                            <option *ngFor="let make of this.AllMakes"
                                                value="{{make.VehicleMakeID_PK}}">
                                                {{make.Name.toUpperCase()}}</option>
                                        </select>
                                        <button (click)="goSell(sellMake.value)" class="col-2 btn-primary"
                                            title="Sell Make Search" type="button"><span
                                                class="icon-search"></span></button>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="mainitem.name == 'sellButton'" class="card">
                                <a href="/sell"><img fetchpriority="high" class="card-img-top" [src]="mainitem.image"
                                        alt="" aria-label="Sell"></a>
                                <div class="card-body">
                                    <p>{{mainitem.text}}</p>
                                    <div class="search">
                                        <button (click)="goSell('null')" class="col-12 btn-primary"
                                            title="Sell Make Search" type="button">ENQUIRE</button>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="mainitem.name == 'service'" class="card">
                                <a href="/service"><img fetchpriority="high" class="card-img-top" [src]="mainitem.image"
                                        alt="" aria-label="Service"></a>
                                <div class="card-body">
                                    <p>{{mainitem.text}}</p>
                                    <div *ngIf="this.Dealerships.length > 1" class="search">
                                        <select (change)="goService(serviceDealer.value)" aria-label="service"
                                            class="col-10" name="" id="" #serviceDealer>
                                            <option value="null" selected hidden>Select Dealership</option>
                                            <optgroup *ngFor="let region of DealerRegions" label="{{region}}">
                                                <option *ngFor="let dealer of regionDealers(region)"
                                                    value="{{dealer.DealershipID_PK}}">
                                                    {{dealer.Name}}</option>
                                            </optgroup>

                                        </select>
                                        <button (click)="goService(serviceDealer.value)"
                                            title="Service Dealership Search" class="col-2 btn-primary "
                                            type="button"><span class="icon-search"></span></button>
                                    </div>
                                    <div *ngIf="!(this.Dealerships.length > 1)" class="search">
                                        <button (click)="goService(this.Dealerships[0].DealershipID_PK)"
                                            title="Service Dealership Search" class="col-12 btn-primary "
                                            type="button">BOOK NOW</button>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="mainitem.name == 'parts'" class="card">
                                <a href="/parts"><img fetchpriority="high" class="card-img-top" [src]="mainitem.image"
                                        alt="" aria-label="Parts"></a>
                                <div class="card-body">
                                    <p>{{mainitem.text}}</p>
                                    <div *ngIf="this.Dealerships.length > 1" class="search">
                                        <select (change)="goParts(partsDealer.value)" aria-label="parts" class="col-10"
                                            name="" id="" #partsDealer>
                                            <option value="null" selected hidden>Select Dealership</option>
                                            <optgroup *ngFor="let region of DealerRegions" label="{{region}}">
                                                <option *ngFor="let dealer of regionDealers(region)"
                                                    value="{{dealer.DealershipID_PK}}">
                                                    {{dealer.Name}}</option>
                                            </optgroup>
                                        </select>
                                        <button (click)="goParts(partsDealer.value)" title="Parts Dealership Search"
                                            class="col-2 btn-primary" type="button"><span
                                                class="icon-search"></span></button>
                                    </div>
                                    <div *ngIf="!(this.Dealerships.length > 1)" class="search">
                                        <button (click)="goParts(this.Dealerships[0].DealershipID_PK)"
                                            title="Service Dealership Search" class="col-12 btn-primary "
                                            type="button">ENQUIRE NOW</button>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="mainitem.name == 'specials'" class="card">
                                <a href="/buy?type=specials"><img fetchpriority="high" class="card-img-top"
                                        [src]="mainitem.image" alt="" aria-label="Specials"></a>
                                <div class="card-body">
                                    <p>{{mainitem.text}}</p>
                                    <div *ngIf="this.Dealerships.length > 1" class="search">
                                        <select (change)="goSpecial(partsDealer.value)" aria-label="specials"
                                            class="col-10" name="" id="" #partsDealer>
                                            <option value="null" selected hidden>Select Dealership</option>
                                            <optgroup *ngFor="let region of DealerRegions" label="{{region}}">
                                                <option *ngFor="let dealer of regionDealers(region)"
                                                    value="{{dealer.DealershipID_PK}}">
                                                    {{dealer.Name}}</option>
                                            </optgroup>
                                        </select>
                                        <button (click)="goSpecial(partsDealer.value)" title="Parts Dealership Search"
                                            class="col-2 btn-primary" type="button"><span
                                                class="icon-search"></span></button>
                                    </div>
                                    <div *ngIf="!(this.Dealerships.length > 1)" class="search">
                                        <button (click)="goSpecial(this.Dealerships[0].DealershipID_PK)"
                                            title="Service Dealership Search" class="col-12 btn-primary "
                                            type="button">ENQUIRE NOW</button>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="mainitem.name == 'commercials'" class="card">
                                <a href="/buy?type=commercials"><img fetchpriority="high" class="card-img-top" [src]="mainitem.image" alt=""
                                        aria-label="Commercials"></a>
                                <div class="card-body">
                                    <p>{{mainitem.text}}</p>
                                    <div *ngIf="!(this.Dealerships.length > 1)" class="search">
                                        <button (click)="goCommercials()"
                                            title="Commercials Dealership Search" class="col-12 btn-primary " type="button">ENQUIRE NOW</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="pagepartType.PageLayoutTypeName == 'content'">
                    <div *ngIf="content" class="col-12 pad">
                        <app-content [Text]="content.HTML"></app-content>
                    </div>
                </div>

                <div *ngIf="pagepartType.PageLayoutTypeName == 'slidebanners'">
                    <app-vehicleseries></app-vehicleseries>
                </div>

                <div *ngIf="pagepartType.PageLayoutTypeName == 'banners'">
                    <div *ngIf="!loadingbanners && this.banners.length>0" class="col-12 pad">
                        <app-slider [images]="banners" [labelBanner]="LabelledBanner"></app-slider>
                    </div>
                </div>

                <div *ngIf="pagepartType.PageLayoutTypeName == 'lateststock'">

                    <div class="pad">
                        <app-vehicles [type]="pagepartType.Value[0]" [vehicletype]="pagepartType.Value[1]"></app-vehicles>
                    </div>
                </div>

                <div *ngIf="pagepartType.PageLayoutTypeName == 'reviews'">
                    <app-customerfeedback></app-customerfeedback>
                </div>
                <div *ngIf="pagepartType.PageLayoutTypeName == 'news'">
                    <app-news [pageModule]="'home'"></app-news>
                </div>
                <div *ngIf="pagepartType.PageLayoutTypeName == 'servicecampaign'">
                    <!-- <app-servicecampaign></app-servicecampaign> -->
                </div>
            </div>
        </div>
    </div>
    <app-servicecampaign></app-servicecampaign>
</div>
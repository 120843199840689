import { Injectable } from '@angular/core';
import { GearsApiService } from './gearsapi.service';
import { SitesService } from './sites.service';

@Injectable({
  providedIn: 'root'
})
export class VehicleMakesService {

  constructor(private apiService: GearsApiService, private siteService: SitesService) {

  }
  getSiteMakes() {
    return new Promise(resolve => {
      this.siteService.get().then((siteid: any) => {

        var brandmakes: any[] = [];
        Promise.all([this.apiService.get('SiteBrands', {
          SiteID_FK: siteid.SiteID_PK,
          PageSize: 9999,
          PageNumber: 1,
          OrderBy: 'Name.Asc',
          Active: true,
          Deleted: false
        })]).then((x: any[]) => {

          x[0].body.forEach((y: any) => {
            y.VehicleMakeID_PK = y.VehicleMakeID_FK;
            y.Name = y.VehicleMakeName;
            brandmakes.push(y);
           


          });

          resolve(brandmakes);
        });
      });
    });

  }
  getStock(type:any = -1) {
    return new Promise(resolve => {
      this.siteService.get().then((siteid: any) => {

        var brandmakes: any[] = [];
        var makes: any[] = [];
        Promise.all([this.apiService.get('SiteBrands', {
          SiteID_FK: siteid.SiteID_PK,
          PageSize: 9999,
          PageNumber: 1,
          OrderBy: 'Name.Asc',
          Active: true,
          Deleted: false
        }),
        this.apiService.get('VehicleMakes', {
          PageSize: 9999,
          PageNumber: 1,
          OrderBy: 'Name.Asc',
          Active: true,
          Deleted: false,
          StockVehicles: true,
          SiteID_FK: siteid.SiteID_PK,
          VehicleStockTypeID_FK: type == -1?undefined:type
        })]).then((x: any[]) => {

          makes = x[1].body;
          x[0].body.forEach((y: any) => {
            y.VehicleMakeID_PK = y.VehicleMakeID_FK;
            y.Name = y.VehicleMakeName;
            brandmakes.push(y);
            makes.splice(makes.findIndex((w: any) => w.VehicleMakeID_PK == y.VehicleMakeID_FK), 1);


          });

          resolve(brandmakes.concat(makes));
        });
      });
    });

  }

  getSpecials() {
    return new Promise(resolve => {
      this.siteService.get().then((siteid: any) => {

        var brandmakes: any[] = [];
        var makes: any[] = [];
        Promise.all([this.apiService.get('SiteBrands', {
          SiteID_FK: siteid.SiteID_PK,
          PageSize: 9999,
          PageNumber: 1,
          OrderBy: 'Name.Asc',
          Active: true,
          Deleted: false
        }),
        this.apiService.get('VehicleMakes', {
          PageSize: 9999,
          PageNumber: 1,
          OrderBy: 'Name.Asc',
          Active: true,
          Deleted: false,
          SiteID_FK: siteid.SiteID_PK,
          SpecialVehicles: true
        })]).then((x: any[]) => {

          makes = x[1].body;
          x[0].body.forEach((y: any) => {
            y.VehicleMakeID_PK = y.VehicleMakeID_FK;
            y.Name = y.VehicleMakeName;
            brandmakes.push(y);
            makes.splice(makes.findIndex((w: any) => w.VehicleMakeID_PK == y.VehicleMakeID_FK), 1);


          });

          resolve(brandmakes.concat(makes));
        });
      });
    });

  }

  getAll() {
    return new Promise(resolve => {
      this.siteService.get().then((siteid: any) => {
        var brandmakes: any[] = [];
        var makes: any[] = [];
        Promise.all([this.apiService.get('SiteBrands', {
          SiteID_FK: siteid.SiteID_PK,
          PageSize: 9999,
          PageNumber: 1,
          OrderBy: 'Name.Asc',
          Active: true,
          Deleted: false
        }),
        this.apiService.get('VehicleMakes', {
          PageSize: 9999,
          PageNumber: 1,
          OrderBy: 'Name.Asc',
          Active: true,
          Deleted: false
        })]).then((x: any[]) => {
          makes = x[1].body;
          x[0].body.forEach((y: any) => {
            y.VehicleMakeID_PK = y.VehicleMakeID_FK;
            y.Name = y.VehicleMakeName;
            brandmakes.push(y);
            makes.splice(makes.findIndex((w: any) => w.VehicleMakeID_PK == y.VehicleMakeID_FK), 1);


          });

          resolve(brandmakes.concat(makes));
        });
      });
    });

  }
}

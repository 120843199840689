import { Component, OnInit } from '@angular/core';
import { GearsApiService } from 'src/app/services/gearsapi.service';
import { SitesService } from 'src/app/services/sites.service';

@Component({
  selector: 'app-customerfeedback',
  templateUrl: './customerfeedback.component.html',
  styleUrls: ['./customerfeedback.component.less']
})
export class CustomerfeedbackComponent implements OnInit {

  constructor(private apiService: GearsApiService, private siteService: SitesService) { }
  selind: number = 0;
  selected: any = {};
  site: any;
  reviews: any[] = [];
  ngOnInit(): void {

    this.siteService.get().then((x: any) => {
      this.site = x;
      this.apiService.get('Feedback', {
        SiteID_FK: this.site.SiteID_PK
      }).then((x: any) => { this.reviews = x.body; this.selected = this.reviews[0]; });
    });
  }

  next() {
    if (!(this.selind == (this.reviews.length - 1))) {
      this.selind++;
      this.selected = this.reviews[this.selind];
    }
    else{
      this.selind = 0;
    }
  }
  prev() {
    if (!(this.selind == 0)) {
      this.selind--;
      this.selected = this.reviews[this.selind];
    }
    else{
      this.selind = (this.reviews.length - 1);
    }
  }
}

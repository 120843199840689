import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { GearsApiService } from './gearsapi.service';
import { DOCUMENT } from '@angular/common';
import { SitesService } from './sites.service';

@Injectable({
  providedIn: 'root',
})
export class ChatserviceService {
  private renderer: Renderer2;
  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private apiService: GearsApiService,
    rendererFactory: RendererFactory2,
    private siteService: SitesService
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  loadChat(pagemodule: any) {
    this.siteService.get().then((x: any) => {
      this.apiService
        .get('SiteChatCodes', {
          Active: 1,
          Deleted: 0,
          PageNumber: 1,
          PageSize: 1,
          OrderBy: '',
          SiteID_FK: x.SiteID_PK,
          SitePage: pagemodule,
        })
        .then((chat: any) => {
          if (chat.body.length > 0) {
            var chats = this.renderer.createElement('div');
              this.renderer.setAttribute(chats, 'id', 'chats');
              this.renderer.setAttribute(chats, 'name', 'script');
              this.renderer.appendChild(this._document.body, chats);

              var snippet = chat.body[0].Snippet;
              var Tokens = JSON.parse(chat.body[0].Tokens);
              var Values = JSON.parse(chat.body[0].TokenValues);

              snippet = snippet.replaceAll('<script>', '');
              snippet = snippet.replaceAll('</script>', '');
              Tokens.forEach((element: any) => {
                snippet = snippet.replaceAll(
                  '$' + element + '$',
                  Values[element]
                );
              });

              let script = this.renderer.createElement('script');
              script.id = 'chat_script';
              script.text = snippet;

            this._document
              .querySelectorAll('[id=chats]')
              .forEach((element) => {
                this.renderer.removeChild(element.parentNode, element);
              });

              

            this._document
              .getElementsByName('whatsapp-chat-widget')
              .forEach((element) => {
                this.renderer.removeChild(element.parentNode, element);
              });

            this._document
              .querySelectorAll('[id=whatsapp-chat-widget]')
              .forEach((element) => {
                this.renderer.removeChild(element.parentNode, element);
              });

            if (this._document.getElementById('chat_script')) {
              this._document.getElementById('chat_script')?.remove();
            }

             
            this._document
            .querySelectorAll('[id=chat-2-desk-widget-container]')
            .forEach((element) => {
              this.renderer.removeChild(element.parentNode, element);
            });
              this.renderer.appendChild(this._document.body, script);
         
          
          } else {
            this.apiService
              .get('SiteChatCodes', {
                Active: 1,
                Deleted: 0,
                PageNumber: 1,
                PageSize: 1,
                OrderBy: '',
                SiteID_FK: x.SiteID_PK,
              })
              .then((chat: any) => {
                if (chat.body.length > 0) {


                  var chats = this.renderer.createElement('div');
                  this.renderer.setAttribute(chats, 'id', 'chats');
                  this.renderer.appendChild(this._document.body, chats);

                  var snippet = chat.body[0].Snippet;
                  var Tokens = JSON.parse(chat.body[0].Tokens);
                  var Values = JSON.parse(chat.body[0].TokenValues);

                  snippet = snippet.replaceAll('<script>', '');
                  snippet = snippet.replaceAll('</script>', '');
                  Tokens.forEach((element: any) => {
                    snippet = snippet.replaceAll(
                      '$' + element + '$',
                      Values[element]
                    );
                  });

                  let script = this.renderer.createElement('script');
                  script.text = snippet;


                  this._document
                  .querySelectorAll('[id=chats]')
                  .forEach((element) => {
                    this.renderer.removeChild(element.parentNode, element);
                  });

                
                  
                  this._document
                    .getElementsByName('whatsapp-chat-widget')
                    .forEach((element) => {
                      this.renderer.removeChild(element.parentNode, element);
                    });
                  this._document
                    .querySelectorAll('[id=whatsapp-chat-widget]')
                    .forEach((element) => {
                      this.renderer.removeChild(element.parentNode, element);
                    });

                  if (this._document.getElementById('chat_script')) {
                    this.renderer.removeChild(
                      this._document.body,
                      this._document.getElementById('chat_script')
                    );
                  }

                  this._document
                  .querySelectorAll('[id=chat-2-desk-widget-container]')
                  .forEach((element) => {
                    this.renderer.removeChild(element.parentNode, element);
                  });
                    this.renderer.appendChild(
                      this._document.getElementById('chats'),
                      script
                    );
                    
                }
              });
          }
        });
    });
  }
}

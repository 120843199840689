import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ElementRef, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs';
import { GearsApiService } from 'src/app/services/gearsapi.service';
import { environment } from 'src/environments/environment';
import { ModalService } from '../modalservice.service';

@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.less']
})
export class FinanceComponent implements OnInit {
  @Input() id?: string;
  isOpen = false;
  private element: any;
  data: any;
  deposit: any = 10;
  months: any = 72;
  interst: any = 12.25;
  balloon: any = 10;
  price: any = 0;
  financeForm!: FormGroup;
  currency: any;

  Regions: any = [];
  Titles: any = [];
  constructor(@Inject(PLATFORM_ID) private platformId: Object,private modalService: ModalService, private el: ElementRef, private apiservice: GearsApiService, private formBuilder: FormBuilder) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    this.financeForm = this.formBuilder.group({
      interst: [12.25, Validators.min(1)]
    });
    if (isPlatformBrowser(this.platformId)) {
       
      this.modalService.add(this);
      document.body.appendChild(this.element);

      this.apiservice.get('Regions', {
        PageSize: 9999,
        PageNumber: 1,
        OrderBy: ''
      }).then((x: any) => {
        this.Regions = x.body;
      });

      this.apiservice.get('Titles', {
        PageSize: 9999,
        PageNumber: 1,
        OrderBy: ''
      }).then((x: any) => {
        this.Titles = x.body;
      });

      this.element.addEventListener('click', (el: any) => {
        if (el.target.className === 'financemodal') {
          this.close();
        }
      });
    }
  }

  ngOnDestroy() {
    this.element.remove();
  }

  open(data: any = null, currency: any = null) {
    if (isPlatformBrowser(this.platformId)) {
      this.element.style.display = 'block';
      document.body.classList.add('jw-modal-open');
      this.isOpen = true;
      this.price = data;
      this.currency = currency;
    }
  }

  close() {
    if (isPlatformBrowser(this.platformId)) {
      this.element.style.display = 'none';
      document.body.classList.remove('jw-modal-open');
      this.isOpen = false;
    }
  }

  dep(n: number) { this.deposit = n }
  mon(n: number) { this.months = n }
  bal(n: number) { this.balloon = n }

  apply() {
    window.location.href = '/financeapplication';
  }
  monthlyPayment() {
    if (this.financeForm.controls['interst'].value > 0) {
      //var dep = 0.1,term = 60,inter = 17;
      var vp = parseInt(this.price), //vehicle price incl vat
        // deposit as percentage. ie 10% will be passed through as 0.1
        dpamt = vp * this.deposit / 100, //deposit amount
        tm = parseInt(this.months), //terms month
        i = this.financeForm.controls['interst'].value / 100, //interest rate
        loan = 0,
        MonPmt = 0,
        balloonAmount = (this.balloon / 100) * vp;
      loan = vp + environment.initfee - dpamt; //additional amounts are normally what mccarthy add on (once off fee R1140 and R57 per month)
      i /= 12;


      MonPmt = Math.ceil((loan * Math.pow((1 + i), tm)) / ((Math.pow((1 + i), tm) - 1) / i) - (balloonAmount / ((Math.pow((1 + i), tm) - 1) / i)));

      return MonPmt + environment.adminfee;
    }
    return 0;
  }

}

import { Component, OnInit } from '@angular/core';
import { GearsApiService } from 'src/app/services/gearsapi.service';
import { SitesService } from 'src/app/services/sites.service';

@Component({
  selector: 'app-whyservice',
  templateUrl: './whyservice.component.html',
  styleUrls: ['./whyservice.component.less']
})
export class WhyserviceComponent implements OnInit {
  banners: any[] = [];
  constructor(private apiservice: GearsApiService,private siteService:SitesService) { }
  content:any;
  ngOnInit(): void {
    this.siteService.get().then((s:any)=>{
    this.apiservice.get('Content', {
      SiteID_FK: s.SiteID_PK,
      ModuleCode: 'whyservice',
      PageSize: 9999,
      PageNumber: 1,
      OrderBy: '',
      Active: 1,
      Deleted: 0
    }).then((x: any) => {
      this.content = x.body[0];
    });

    this.apiservice.get('Banners', {
      SiteID_FK: s.SiteID_PK,
      Module: 'Book a Service',
      PageSize: 9999,
      PageNumber: 1,
      OrderBy: ''
    }).then((x: any) => {
      this.banners = x.body;
    });
  });
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GearsApiService } from 'src/app/services/gearsapi.service';
import { SitesService } from 'src/app/services/sites.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dealers',
  templateUrl: './dealers.component.html',
  styleUrls: ['./dealers.component.less']
})
export class DealersComponent implements OnInit {

  constructor(private apiservice: GearsApiService, private siteservice: SitesService, private formBuilder: FormBuilder) {
    this.searchForm = this.formBuilder.group({
      search: ['']
    });
  }
  Dealers: any[] = [];
  banners: any[] = [];
  loading: boolean = false;
  searchShowing = false;
  searchline!: any;
  searchForm!: FormGroup;
  currentSite: any;
  filteredDealerships(): any[] {
    return this.Dealers.filter((x: any) => x.Name.toUpperCase().includes(this.searchForm.controls['search'].value.toUpperCase()) 
    || (x.Region&&x.Region.toUpperCase().includes(this.searchForm.controls['search'].value.toUpperCase()) )
    || (x.PhoneNumber&&x.PhoneNumber.toUpperCase().includes(this.searchForm.controls['search'].value.toUpperCase())) 
    || (x.AddressPhysicalSuburb&&x.AddressPhysicalSuburb.toUpperCase().includes(this.searchForm.controls['search'].value.toUpperCase()))
    || (x.AddressPhysicalStreet&&x.AddressPhysicalStreet.toUpperCase().includes(this.searchForm.controls['search'].value.toUpperCase()))
    || (x.AddressPhysicalCity&&x.AddressPhysicalCity.toUpperCase().includes(this.searchForm.controls['search'].value.toUpperCase()))
    || (x.AddressPhysicalAreaCode&&x.AddressPhysicalAreaCode.toUpperCase().includes(this.searchForm.controls['search'].value.toUpperCase())))
  }
  searchfocus(state: boolean) {
    setTimeout(() => {
      this.searchShowing = state;
    }, 500);

  }
  ngOnInit(): void {

    this.siteservice.get().then((s: any) => {
      this.currentSite = s;    
      let orderby = (this.currentSite.layout
        .filter((s:any) =>{if (s.PageLayoutModuleName == 'bottomnav')return s.PageLayoutType}))[0].PageLayoutType
        .find((data:any) => data.PageLayoutTypeName == 'dealership');
      orderby = orderby ? orderby.Value : '';
      this.apiservice.get("Dealerships", { SiteID_FK: s.SiteID_PK, PageSize: 9999, PageNumber: 1, OrderBy: orderby }).then((x: any) => {
        this.Dealers = x.body;
        /*this.Dealers.forEach((dealer:any) => {
          if (!dealer.ServiceOnlineLink){
            if (dealer.DealershipID_PK){
              dealer.ServiceOnlineLink = '/service?dealership='+dealer.DealershipID_PK;
            }
            else{
              dealer.ServiceOnlineLink = '/service';
            }
          }
        });*/
      });
      this.apiservice.get('Banners', {
        SiteID_FK: s.SiteID_PK,
        Module: 'Dealer Locator',
        PageSize: 9999,
        PageNumber: 1,
        OrderBy: ''
      }).then((x: any) => {
       
        this.banners = x.body;
        this.loading = false;
      });
    }); 
  }

}

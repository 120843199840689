<div class="cookiepolicy">
    <h2>COOKIE POLICY</h2>
    
    <h3>What are cookies and why do we use them?</h3>
    
    <p>
    We might use cookies and other techniques such as web beacons when you visit our website.
    “Cookies” are small text files used by us to recognise repeat users, facilitate your on-going access to
    and use of our website and allow us to track your usage behaviour and compile aggregate data that
    will allow us to improve the functionality of our website and content. “Web beacons” are small, clear
    picture files used to follow your movements on our website. For example, storing your preferred
    settings for the next time you visit.<br>
    The information we collect from cookies enables us to:
    <ul>
    <li>tailor our websites to your personal needs;</li>
    <li>remember the notifications that you have been shown, so that you are not shown them again;</li>
    <li>help us find information once you have logged in;</li>
    <li>help us link your browsing information to you and your personal information, for example, when
    you choose to register for a service;</li>
    <li>make improvements and updates to our websites based on the way you want to use them; and</li>
    <li>we generally do not use cookies to identify you personally.</li>
    </ul>
    The type of information collected by cookies is not used to personally identify you.
    
    <h3>What kind of cookies do we use?</h3>
    <p>
    We use the following categories of cookies:
    <ul class="categories">
    <li><h4>Category 1: necessary cookies</h4></li>
    <li>Necessary cookies enable you to use our websites and all its features, such as enabling
    access to secure areas of the website. Without these cookies you may not be able to use all
    the features of our websites.</li>
    <li><h4>Category 2: performance cookies</h4></li>
    <li>Performance cookies collect information about how you use our websites so we can improve
    them for you in future. For example, they collect information on which pages you visit most
    often and any error messages you may get. The information collected by these cookies is
    anonymous. They do not collect any information that can identify you personally.</li>
    <li><h4>Category 3: functionality cookies</h4></li>
    <li>Functionality cookies enable us to provide you with a more personalized experience. They
    help us to remember data, such as your username and language preference, to save you
    having to enter them again the next time you visit. The information these cookies collect is
    anonymous. They cannot track your browsing activity on other websites.</li>
    <li><h4>Category 4: targeting/advertising cookies</h4></li>
    <li>We and our service providers may use advertising cookies to deliver ads that we believe are
    relevant to you and your interests.
    For example, we may use targeting or advertising cookies to customize the advertising and
    content you receive on our websites, to limit the number of times you see the same ad on our
    websites and to help measure the effectiveness of our advertising campaigns.</li>
    <li><h4>Category 5: social media cookies</h4></li>
    <li>Our websites use third-party cookies from social media sites such as Facebook, Twitter and
    LinkedIn to enable in-depth campaign reporting and to track social network users when they
    visit our websites, by using a tagging mechanism provided by those social networks. These
    cookies can also be used for event tracking and remarketing purposes. Any data collected
    with these tags will be used in accordance with our privacy policy and with the social
    network's privacy policies. Our websites will not collect or store any personally identifiable
    information from the user.</li>
    <li><h4>How can you manage your cookie settings?</h4></li>
    <li>To ensure you get the best possible experience when visiting our websites, we recommend
    that you accept cookies. However, you can block/disable the use of cookies via settings in
    your web browser.
    Cookies may, however, be necessary to provide you with certain features available on our
    website. If you disable cookies you may not be able to use these features, and your access
    to our website will be limited.</li>
    </ul>
    
    </div>
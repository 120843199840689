import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, Input, PLATFORM_ID, Inject } from '@angular/core';
import { ModalService } from 'src/app/modules/modalservice.service';
import { SitesService } from 'src/app/services/sites.service';
import { TaskServiceService } from 'src/app/services/task-service.service';


@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.less'],
})
export class NavBarComponent implements OnInit {
  currentSite:any = {};
  topnavbuttons: any;
  currentlocation: any;
  centerLogo: boolean = false;
  constructor(private siteService:SitesService,@Inject(PLATFORM_ID) private platformId: Object, private modalservice: ModalService, public taskservice:TaskServiceService) { }
  @Input() layout?: any;
  @Input() navbar?: any;
  ngOnInit(): void {
    this.taskservice.navUpdate();
    this.siteService.get().then((x:any)=>{
      this.currentSite = x;
      const topnav = this.currentSite.layout.filter((s:any) =>{if (s.PageLayoutModuleName == 'topnav')return s.PageLayoutType})[0]?.PageLayoutType;
      this.topnavbuttons = 
                topnav.find((data:any) => data.PageLayoutTypeName == 'topnavbutton')?.Value
                ?.filter((buttons: any) => {if ((buttons.name == 'specials' && this.currentSite.NumberOfSpecials > 0) || buttons.name != 'specials')
                {return buttons}});
      this.currentSite.hideFavorites = !(topnav?.find((data:any) => data.PageLayoutTypeName == 'hidefavorites'));
      this.centerLogo = topnav.some((plt:any)=> plt.PageLayoutTypeName === "centerlogo");
    });
  }

  isfav(){
    if (isPlatformBrowser(this.platformId)) {
    return JSON.parse(localStorage.getItem('fav')||'[]').length > 0;
    }else{
      return false;
    }
  }

  enquire() {
    var data = {
      Name: "Contact Us",
      Dealer: null,
      Type: "ContactUs"
    };
    this.modalservice.open('enquire', data);
  }

}

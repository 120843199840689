import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GearsApiService } from 'src/app/services/gearsapi.service';
import { SitesService } from 'src/app/services/sites.service';

@Component({
  selector: 'app-servicecampaign',
  standalone: true,
  imports: [CommonModule,FormsModule],
  templateUrl: './servicecampaign.component.html',
  styleUrls: ['./servicecampaign.component.less']
})
export class ServicecampaignComponent implements OnInit {
  constructor(
    private siteService: SitesService,
    private el: ElementRef,
    private api: GearsApiService
  ) {}
  availableSlot:any;
  calendar_data:any[] = [];
  displayCalendar:any;
  calendarIndex:number=0;
  serviceCampaign:any;
  monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  Dealerships:any;
  selectedDealershipID:number=0;
  ngOnInit(): void {
    this.siteService.get().then((x: any) => {
      const currentSite = x;
      this.api.get('Dealerships',{
        Active: true,
        Deleted: false,
        SiteID_FK: currentSite.SiteID_PK,
        OrderBy: '',
        PageNumber: 1,
        PageSize: 9999
      }).then((x:any)=> {
        this.Dealerships = x.body;
      })
      const layoutHome = currentSite.layout.find((plm:any)=> plm.PageLayoutModuleName == 'home').PageLayoutType;
      this.serviceCampaign = layoutHome.find((plt:any)=> plt.PageLayoutTypeName == 'servicecampaign');
      const dateArr:any[] = [];
      if (this.serviceCampaign.Value) {
        for (let i = 0; i<this.serviceCampaign.Value.length; i++) {
          const date = new Date(this.serviceCampaign.Value[i]);
          const objDate = {
            year: date.getFullYear(),
            month: date.getMonth()+1
          }
          if (dateArr.findIndex((d:any)=> d.year == objDate.year && d.month == objDate.month) == -1) {
            dateArr.push(objDate);
          }
        }
        dateArr.sort((a:any,b:any)=> {
          if (a.year !== b.year) {
            return a.year - b.year; // Sort by year
          }
          return a.month - b.month; // Sort by month
        });
        for (let i = 0; i<dateArr.length; i++){
          const month = {
            year: dateArr[i].year,
            month:  dateArr[i].month,
            data: this.generateCalendar(dateArr[i].year, dateArr[i].month)
          }
          this.calendar_data.push(month);
        }
        if (this.calendar_data && this.calendar_data.length > 0) this.displayCalendar = this.calendar_data[0].data;
        let availableSlotExpiry = JSON.parse(localStorage.getItem('availableSlot')??'{}');
        if (availableSlotExpiry) {
          if (Date.now() <= Date.parse(availableSlotExpiry.Expiry)) {
            this.availableSlot = availableSlotExpiry.Slots;
            this.displaySlotCount();
          } else {
            const slots = (Math.floor(Math.random() * 15) + 8).toString();
            var date = new Date();
            // add a day
            date.setDate(date.getDate() + 1);
            availableSlotExpiry = {
              Expiry: date,
              Slots: slots
            };
            localStorage.setItem('availableSlot', JSON.stringify(availableSlotExpiry));
            this.availableSlot = availableSlotExpiry.Slots;
            this.displaySlotCount();
          }
        } else {
          const slots = (Math.floor(Math.random() * 15) + 8).toString();
          var date = new Date();
          // add a day
          date.setDate(date.getDate() + 1);
          availableSlotExpiry = {
            Expiry: date,
            Slots: slots
          };
          localStorage.setItem('availableSlot', JSON.stringify(availableSlotExpiry));
          this.availableSlot = availableSlotExpiry.Slots;
          this.displaySlotCount();
        }
        
      }
    });
  }

  displaySlotCount(){
    setTimeout(() => {
      const slotCountElement = this.el.nativeElement.querySelector('.slot-count');
      if (slotCountElement) {
        slotCountElement.textContent = this.availableSlot;
      } else {
        this.displaySlotCount();
      };
    },1000);
  }

  generateCalendar(year:number, month:number){
    let weeks:any[] = [];
    let firstDay:any = new Date(year, month-1, 1);
    firstDay = firstDay.getDay();
    const daysInMonth = new Date(year, month, 0);
    let day = 1;

    let week = Array(firstDay).fill('');
    
    while (day <= daysInMonth.getDate()) {
      week.push(day);

      if (week.length === 7) {
        weeks.push(week);
        week = [];
      }
      day++;
    }

    if (week.length != 0) {
      while (week.length < 7) {
        week.push('');
      }
      weeks.push(week);
    }


    return weeks;
  }

  goToService(year:number,month:number,day:number){
    if (this.selectedDealershipID!=0) {
      window.location.pathname=`/service/${year}-${month}-${day}/${this.selectedDealershipID}`;
    }
  }

  changeMonth(num:number){
    this.calendarIndex = this.calendarIndex+num;
    this.displayCalendar = this.calendar_data[this.calendarIndex].data;
  }

  isActiveDate(year:number,month:number,day:number){
    return (this.serviceCampaign.Value.includes(`${year}/${month}/${day}`)&&this.selectedDealershipID!=0);
  }

}

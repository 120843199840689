import { Component, OnInit } from '@angular/core';
import { GearsApiService } from 'src/app/services/gearsapi.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-alertlinks',
  templateUrl: './alertlinks.component.html',
  styleUrls: ['./alertlinks.component.less'],
})
export class AlertlinksComponent implements OnInit {
  constructor(
    private apiservice: GearsApiService,
    private route: ActivatedRoute,
    private location: Location
  ) {}

  path: any = '';
  id: any = '';
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => { this.id = params['id']});
    this.path = location.pathname;

    if (this.path == '/verifyalert') {
      this.verifyAlert();
    }
    if (this.path == '/alertoptout') {
      this.optoutAlert();
    }
  }

  verifyAlert() {
    this.apiservice
    .getSingle('Alerts/verify', this.id, {})
    .subscribe((x: any) => {
      window.location.href = '/verifiedalert/thankyou';
    });
    
  }

  optoutAlert() {
    this.apiservice
    .getSingle('Alerts/optout', this.id, {})
    .subscribe((x: any) => {
      window.location.href = '/optoutalert/thankyou';
    });
    
  }
}

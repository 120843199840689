<div *ngIf="article" class="row details">
    <!--<a class="offset-md-1" (click)="back()">
        <h6><span class="icon-back"></span> Back</h6>
    </a>-->
    <h5 class="col-12 col-sm-10 offset-sm-1">{{article.ArticleCategory}}</h5>
    <h2 class="col-12 col-sm-10 offset-sm-1">{{article.Title}}</h2>
    
    <div class="col-12 col-sm-10 offset-sm-1 title">
        <p> <span></span> {{this.article.Author}} </p>
        <p><a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u={{thislocation}}&amp;src=sdkpreparse">
                    <span class="icon-facebook"></span>
                    Share to Facebook
                </a></p>
               <!-- <p><a href="#" socialshare socialshare-provider="facebook" socialshare-text="'article.Title'" socialshare-url="CurrentURL"
                    socialshare-quote="article.Description" socialshare-type="feed" socialshare-media="article.ImgFilename">
                    <span class="icon-mail"></span>
                    Share to Email
                </a></p>-->
                <p><a href="https://twitter.com/intent/tweet?text={{article.Title}} {{thislocation}}" target="_blank">
                    <span class="icon-twitter2"></span>
                    Share to Twitter
                </a></p>
    </div>
    <div class="col-12 col-sm-10 offset-sm-1 text-center">
        <img loading="lazy" *ngIf="article.ImgFilename && article.ImgFilename != 'N/A'" src="{{imgadd(article.ImgFilename)}}">
        <img loading="lazy" *ngIf="!article.ImgFilename || article.ImgFilename == 'N/A'" src="{{currentSite.vehicleImages.Value}}" alt="">
    </div>
    <div class="col-12 col-sm-10 offset-sm-1 article" *ngIf="article.ExternalReference">
        <div [innerHtml]="section.Text" *ngFor="let section of sections"></div>
    </div>
    <div class="col-12 col-sm-10 offset-sm-1 article" *ngIf="!article.ExternalReference">
        <div *ngFor="let section of sections"><b *ngIf="section.Title" [innerHTML]="section.Title"><br/><br/></b><pre [innerHTML]="section.Text"></pre></div>
    </div>
    <div class="col-12 col-sm-10 offset-sm-1"><button class="btn-primary col-12" (click)="back()">
        <span class="icon-back"></span> Return to Previous Page</button>
    </div>
</div>
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { resolve } from 'path';

@Injectable({
  providedIn: 'root'
})
export class CampaignsService {

  constructor(private route: ActivatedRoute) { }

  // Save Google tracking data
  public saveGoogleTracking() {
    return new Promise(async (Resolve) => {
      var utmTracking: { [key: string]: string } = {};
      this.route.queryParams.subscribe((qparams) => {
        for (let key in qparams) {
          if (key.includes('utm_')){
            utmTracking[key] = qparams[key];
          }
        }
        if (Object.keys(utmTracking).length > 0){
          localStorage.setItem('utm_tracking', JSON.stringify(utmTracking))
        }
      });
      Resolve("Saved");
    })
  }

  // Get Google tracking data
  public getGoogleTracking() {
      return localStorage.getItem('utm_tracking');
  }

}

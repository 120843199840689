<div  *ngIf="isOpen" class="alertsmodal">
    <div class="card">
        <div class="card-header">
            Terms and Conditions
            <a (click)="close()"><h4><span class="icon-cross"></span></h4></a>
        </div>
        <div class="card-body">
     
                <div class="row">
                    <div class="termsspace2 termbold2 margintopbot">These terms govern your use of the website.</div>
                    <div class="termsspace2 grey ng-binding space">By accessing, visiting or using the website, you accept and agree to the terms. If there is a conflict between these website terms of use and any other rules, policies, notices or special terms agreed between you and , the following order will prevail: (i) special terms; (ii) website terms of use; (iii) privacy policy and (iv) other rules, policies or notices.</div>
                    
                    <div class="termsspace2 termhead margintopbot">1. Definitions</div>
                    <div class="termsspace2 grey">In the terms:</div>
                    
                    <ul>
                        <li class="termsspace grey termslist ng-binding"><span class="termbold">1.1</span> "" or "we" or "us" means the ;</li>
                        <li class="termsspace grey termslist ng-binding"><span class="termbold">1.3</span> "service" means the provision of information to users about the goods that  dealers list on the website;</li>
                        <li class="termsspace grey termslist ng-binding"><span class="termbold">1.4</span> "terms" means these website terms of use and any other rules, policies, notices or special terms agreed between you and ;</li>
                        <li class="termsspace grey termslist"><span class="termbold">1.5</span> "user" or "you" means any person who accesses, visits or uses the website;</li>
                        <li class="termsspace grey termslist ng-binding"><span class="termbold">1.6</span> "website" means </li>
                    </ul>
                    <div class="termsspace2 termhead">2. Interpretation</div>
                    <ul>
                        <li class="termsspace grey termslist">2.1 Headings are inserted only for reference purpose and will not affect the interpretation of the terms. A reference to a person includes a legal person. The rule that an agreement will be interpreted against the party who drafted the agreement will not apply.</li>
                    </ul>
                    <div class="termsspace2 termhead margintopbot">3. Service</div>
                    <ul>
                        <li class="termsspace grey termslist ng-binding"><span class="termbold">3.1</span>  provides a marketing tool to  dealers through which  dealers can list vehicles on the website and customers' enquiries about the listed vehicles are thereafter channelled to the relevant  dealers. A consumer who eventually purchases a vehicle via  therefore enters into a direct contract of purchase and sale with the relevant  dealer that is supplying the vehicle.</li>
                        <li class="termsspace grey termslist ng-binding"><span class="termbold">3.2</span>  provides the service to the user on these terms. By providing the service,  does not make any offer to you, but merely provides the information received from the  dealers to you.</li>
                    </ul>
                    <div class="termsspace2 termhead margintopbot">4. Web site use</div>
                    <ul>
                        <li class="termsspace grey termslist"><span class="termbold">4.1</span> You may only use the website for personal use, limited to viewing the website, providing information to the website and downloading product information for your personal review from the website.</li>
                        <li class="termsspace grey termslist ng-binding"><span class="termbold">4.2</span>  gives you a limited, revocable licence to use the website subject to the terms only.</li>
                        <li class="termsspace grey termslist"><span class="termbold">4.3</span> Your licence does not extend to the website's source codes or to the source code of any software or computer program that forms part of the website.</li>
                        <li class="termsspace grey termslist"><span class="termbold">4.4</span> You may only use the website if in terms of South African law you have the legal capacity to enter into an agreement.</li>
                        <li class="termsspace grey termslist"><span class="termbold">4.5</span> You may only link to the website by linking to the home page of the website.</li>
                        <li class="termsspace grey termslist ng-binding"><span class="termbold">4.6</span>  may at any time and for any reason and without notice, suspend, discontinue or amend the website and content.</li>
                    </ul>
                    <div class="termsspace2 termhead margintopbot">5. Restrictions</div>
                    <div class="termsspace2 grey">You may not:</div>
                    <ul>
                        <li class="termsspace grey termslist ng-binding"><span class="termbold">5.1</span> provide any untrue or incorrect information to the website or ;</li>
                        <li class="termsspace grey termslist"><span class="termbold">5.2</span> modify copy, decompile or reverse engineer the website or use the website to make derivative copies;</li>
                        <li class="termsspace grey termslist ng-binding"><span class="termbold">5.3</span> lease, sell, assign or in any other way distribute the website or any information obtained from the website without the prior written consent of ;</li>
                        <li class="termsspace grey termslist"><span class="termbold">5.4</span> use malicious search technology, including but not limited to spiders and crawlers;</li>
                        <li class="termsspace grey termslist"><span class="termbold">5.5</span> frame any pages of the website;</li>
                        <li class="termsspace grey termslist"><span class="termbold">5.6</span> deep link to any pages of the website in a way to suggest that you are the owner or licensee of any intellectual property in the website;</li>
                        <li class="termsspace grey termslist"><span class="termbold">5.7</span> incorporate any material from the website in any other work, publication or website either of your own or belonging to any third party;</li>
                        <li class="termsspace grey termslist ng-binding"><span class="termbold">5.8</span> use the website, to post any material which in 's discretion is false, defamatory, inaccurate, abusive, vulgar, hateful, harassing, obscene, profane, sexually oriented, threatening, invasive of a person's privacy, or otherwise violates any laws. You submit all information at your sole risk and responsibility.  is not responsible or liable for damages or other adverse consequences caused by the use of the information you submit to the website.</li>
                    </ul>
                    <div class="termsspace2 termhead margintopbot">6 Disclaimers and disclosure</div>
                    <ul>
                        <li class="termsspace grey termslist ng-binding"><span class="termbold">6.1</span>  publishes information on its website as a convenience to its users for information purposes only.  receives the information from  dealers.  will attempt to provide correct information but as the information is received from  dealers,  cannot guarantee that all information will always be correct.  will correct any incorrect information as soon as reasonably possible after it comes to their attention.
                        </li>
                        <li class="termsspace grey termslist ng-binding"><span class="termbold">6.2</span> Subject to applicable law  provides the website "as is" and "as available" and disclaims all express or implied warranties, representations and endorsements, including but not limited to warranty of title, merchantability, non-infringement, fitness for a particular purpose and that the website will be free from defects, uninterrupted and error free.</li>
                        <li class="termsspace grey termslist ng-binding"><span class="termbold">6.3</span> Although  has made every effort to display the goods and their colours as accurately as possible, the displayed products and colours of the products will also depend on your equipment and  cannot guarantee that your equipment will display an accurate representation of the actual goods or their colours. You also need to note that as indicated on the images itself, the images displayed are not necessarily images of the goods that are available for sale but may merely be an example of the goods in stock.</li>
                        <li class="termsspace grey termslist ng-binding"><span class="termbold">6.4</span> We update the website weekly. This means that a vehicle displayed on the website may not be available for sale at the time you visit the website. You need to confirm stock availability with the relevant  dealer directly.</li>
                        <li class="termsspace grey termslist ng-binding"><span class="termbold">6.5</span> Although the website may link to other sites,  is not directly or indirectly, implying any approval, association, sponsorship, endorsement or affiliation with the linked site, unless specifically stated therein. You acknowledge and agree that  has not reviewed all sites linked to the website and is not responsible for the content of any off-site pages or any other sites linked to the website. If you link to other off-site pages or other sites, you do that at your own risk.</li>
                    </ul>
                    <div class="termsspace2 termhead margintopbot">7. Indemnity</div>
                    <ul>
                        <li class="termsspace grey termslist ng-binding"><span class="termbold">7.1</span> Subject to applicable law, you indemnify  against any losses that you may suffer as a result of the service, including economic and consequential loss. The indemnity excludes damages that you may suffer as a result of gross negligence and intent on the side of .</li>
                    </ul>
                    <div class="termsspace2 termhead margintopbot">8. Copyright and Intellectual Property Rights</div>
                    <ul>
                        <li class="termsspace grey termslist ng-binding"><span class="termbold">8.1</span> All content, trademarks and data on this web site, including but not limited to, software, databases, text, graphics, icons, hyperlinks, private information, and designs are the property of or licensed to , and as such, are protected from infringement by domestic and international legislation and treaties.</li>
                        <li class="termsspace grey termslist ng-binding"><span class="termbold">8.2</span> All rights not expressly granted are reserved. The copyright in all content, information, material and logos vesting in  shall continue to vest in  and no right, title or interest in any proprietary material or information contained in this website is granted to you.</li>
                        <li class="termsspace grey termslist ng-binding"><span class="termbold">8.3</span>  owns or has the legal rights to all of the website, including but not limited to all of the wallpaper, icons, characters, artwork, images, graphics, music, text, software and any other content of the website (the "content"), and all HTML design, layouts, configurations, CGI and other code and scripts in any format used to implement the website (the "code"). The content and code of the website are protected by intellectual property and related rights, including but not limited to copyright, patent, database and trademark rights. All logos, names and trademarks, which appear on the website are the intellectual property of  or are used by  under license.  will prosecute any violation of intellectual property rights to the fullest extent that the law permits.</li>
                    </ul>
                    <div class="termsspace2 termhead margintopbot">9. Confidentiality</div>
                    <ul>
                        <li class="termsspace grey termslist ng-binding"><span class="termbold">9.1</span> If you respond to  via e-mail, surveys, forums, registration, or any other communication medium with any information, including but not limited to feedback, data, questions, comments or suggestions, but excluding your personal data, the information will not be deemed confidential.</li>
                        <li class="termsspace grey termslist ng-binding"><span class="termbold">9.2</span> By sending information you grant to  an irrevocable, worldwide, perpetual and transferable right to use these submissions without any limitations for any purposes, which  considers necessary. Subject to the privacy policy  will not be liable for any use or disclosure of such information and will not have any obligation to keep such information confidential and will be free to reproduce, use, disclose and distribute the information to others without limitation.  will be free to use any ideas, concepts, know-how or techniques contained in the information for any purpose, including but not limited to developing, manufacturing and marketing products, which incorporate or otherwise rely on the information.</li>
                        <li class="termsspace grey termslist ng-binding"><span class="termbold">9.3</span>  welcomes your comments and suggestions on its website and service, but  does not seek to solicit any confidential or proprietary ideas, suggestions, materials or other information relating to developing, designing, redesigning, modifying, manufacturing or marketing its service. By submitting information, you warrant that  may publish the information, use it as part of its operations, and incorporate its concepts in the service or for any other purposes, which  considers necessary, without liability.</li>
                        <li class="termsspace grey termslist ng-binding"><span class="termbold">9.4</span>  will deal with your personal information in terms of the privacy policy.</li>
                    </ul>
                    <div class="termsspace2 termhead margintopbot">10. Termination</div>
                    <ul>
                        <li class="termsspace grey termslist ng-binding"><span class="termbold">10.1</span> The terms will terminate if  discontinues the website.</li>
                        <li class="termsspace grey termslist ng-binding"><span class="termbold">10.2</span>  may immediately terminate use of and access to the website if you breach the terms. This will be without prejudice to any other remedies and rights in terms of the law, including but not limited to claims for specific performance and damages.</li>
                    </ul>
                    <div class="termsspace2 termhead margintopbot">11. General</div>
                    <ul>
                        <li class="termsspace grey termslist ng-binding"><span class="termbold">11.1</span> The terms constitute the whole agreement between you and .</li>
                        <li class="termsspace grey termslist"><span class="termbold">11.2</span> If any term is found to be void, illegal or unenforceable, the term will be severed from the terms and the remainder of the terms will continue in full force and effect.</li>
                        <li class="termsspace grey termslist"><span class="termbold">11.3</span> The agreement is regulated by South African law.</li>
                    </ul>
                    <br>

                </div>
          
            <button (click)="close()" class="btn-primary col-3">Close</button>
        </div>
    </div>
</div>


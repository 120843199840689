import { Inject, Injectable, OnInit, PLATFORM_ID } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { GearsApiService } from '../services/gearsapi.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class HeaderInterceptor implements OnInit, HttpInterceptor {
    gettingNewToken: boolean = false;
    constructor(private apiService: GearsApiService, private http: HttpClient,@Inject(PLATFORM_ID) private platformId: Object) { //this.getNewTokenIfRequired(); 
    }

    ngOnInit(): void {
        //this.getNewTokenIfRequired();
    }

    intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {





        const headers = this.getHeaders(req, req.url.includes("CustomerFiles"));

        const authReq = req.clone({ headers });

        return next.handle(authReq);



    }

    getHeaders(req: any, fileUpload = false): HttpHeaders {

        let headerObj = new HttpHeaders();
        const requestType = req.method.toLowerCase();
        if (!req.url.includes('AuthToken') && isPlatformBrowser(this.platformId)) {
            //var token = this.getNewTokenIfRequired();
            var token = sessionStorage.getItem('token');
            //while(!this.storage.getItem('token'));
            if (token) {
                // Token For McCarthy API
                headerObj = headerObj.set('Authorization-Token', token);

                // Token for Ceres API
                //headerObj = headerObj.set('Authorization', 'Bearer ' + token);

                headerObj = headerObj.set('Authorization-ID', environment.API_ID);
                //headerObj = headerObj.set('API_Key', environment.api_key);
            }

            if ((requestType === 'post' || requestType === 'put') && !fileUpload) {
                headerObj = headerObj.set('Content-Type', 'application/json');
            } else if (requestType === 'get') {
                headerObj = headerObj.set('Cache-Control', 'no-cache');
                headerObj = headerObj.set('Pragma', 'no-cache');
            }

            return (headerObj);

        } else {


            if ((requestType === 'post' || requestType === 'put') && !fileUpload) {
                headerObj = headerObj.set('Content-Type', 'application/json');
            } else if (requestType === 'get') {
                headerObj = headerObj.set('Cache-Control', 'no-cache');
                headerObj = headerObj.set('Pragma', 'no-cache');
            }

            return headerObj;
        }

    }

    getNewTokenIfRequired(): string | null {
        const helper = new JwtHelperService();
        var token = sessionStorage.getItem('token');

        if (token) {
            const expirationDate = helper.getTokenExpirationDate(token.toString());
            const expired = helper.isTokenExpired(token.toString());

            if (expirationDate) {
                const today = Date.now();
                const expiryDate2MinsLess = expirationDate.getTime() - 2 * 60000;
                const canRefresh = today >= expiryDate2MinsLess;
                this.gettingNewToken = JSON.parse(sessionStorage.getItem('gettingtoken')||'false');
                if (expired && !this.gettingNewToken && canRefresh) {
                    sessionStorage.setItem('gettingtoken','true');
                   this.apiService.getAuth()
                        .subscribe((x: any) => {
                            sessionStorage.setItem('token', x.body.Data.AuthToken);
                            
                            return (sessionStorage.getItem('token'));
                        });

                } else { return (sessionStorage.getItem('token')); }
            } else { return (sessionStorage.getItem('token')); }
        }
        else {
            if (!this.gettingNewToken) {
                //this.gettingNewToken = true;
                this.apiService.getAuth()
                    .subscribe((x: any) => {

                        sessionStorage.setItem('token', x.body.Data.AuthToken);
                        this.gettingNewToken = false;
                        return (sessionStorage.getItem('token'));
                    });
            }

        }
        //while(!storage.getItem('token'));
        return (sessionStorage.getItem('token'));

    }
}
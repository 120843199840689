import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/modules/modalservice.service';
import { GearsApiService } from 'src/app/services/gearsapi.service';
import { SitesService } from 'src/app/services/sites.service'
import { environment } from 'src/environments/environment';
import { CampaignsService } from 'src/app/services/campaigns.service';

@Component({
  selector: 'app-parts',
  templateUrl: './parts.component.html',
  styleUrls: ['./parts.component.less']
})
export class PartsComponent implements OnInit {
  banners: any[] = [];
  Title: any = "";
  Regions: any = [];
  Titles: any = [];
  Dealerships: any = [];
  loading: any = false;
  formParts!: FormGroup;
  failedsubmit: any = false;
  submitting: any = false;
  Site: any;
  constructor(private apiservice: GearsApiService, private modalService: ModalService, private siteService: SitesService, private route: ActivatedRoute, private formBuilder: FormBuilder, private campaignService: CampaignsService) {
  }
  siteid: any = 0;
  ngOnInit(): void {
    this.formParts = this.formBuilder.group({
      dealer: [-1, Validators.min(0)],
      title: [-1],
      region: [-1],
      partdesc: [null, Validators.required],
      modeldesc: [null, Validators.required],
      name: [null, Validators.required],
      surname: [null, Validators.required],
      mail: [null, [Validators.required, Validators.email, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]],
      phone: [null, [Validators.required, Validators.pattern(/^(?:[\+][0-9])?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/)]],
      marketing: [false],
      tcs: [false, Validators.requiredTrue]
    });
    this.route.queryParams.subscribe(params => { this.formParts.controls['dealer'].setValue(params['dealership'] || -1) });
    this.loading = true;
    this.siteService.get().then((x: any) => {
      this.siteid = x.SiteID_PK;
      this.Site = x;
      this.apiservice.get('Banners', {
        SiteID_FK: this.siteid,
        Module: 'Parts',
        PageSize: 9999,
        PageNumber: 1,
        OrderBy: ''
      }).then((x: any) => {

        this.banners = x.body;
        this.loading = false;
      });

      this.apiservice.get('Dealerships', {
        PageSize: 9999,
        PageNumber: 1,
        OrderBy: '',
        SiteID_FK: this.siteid
      }, true).then((x: any) => {
        this.Dealerships = x.body.filter((d:any) => d.PartsDealer == true);;
        if (this.Dealerships.length == 1) {
          this.formParts.controls['dealer'].setValue(this.Dealerships[0].DealershipID_PK)
        }
        this.Dealerships.forEach((x: any) => {
          if (this.Regions.findIndex((y: any) => y.RegionID_PK == x.RegionID_FK) < 0)
            this.Regions.push({ Name: x.Region, RegionID_PK: x.RegionID_FK })
        });
        this.Regions.sort((a: any, b: any) => {
          if (a.Name < b.Name) {
            return -1;
          }
          if (a.Name > b.Name) {
            return 1;
          }
          return 0;
        });
      });

    });


    this.apiservice.get('Titles', {
      PageSize: 9999,
      PageNumber: 1,
      OrderBy: ''
    }).then((x: any) => {
      this.Titles = x.body;
    });


  }
  regionDealers(region: any) {
    return this.Dealerships.filter((x: any) => x.Region == region);
  }

  submit() {
    if (this.formParts.invalid) {
      this.failedsubmit = true;
    }
    else {
      this.submitting = true;
      var post = {
        Active: 1,
        ContactNo: this.formParts.controls['phone'].value,
        DealershipID_FK: this.formParts.controls['dealer'].value,
        Deleted: 0,
        Email: this.formParts.controls['mail'].value,
        EnquiryTypeID_FK: 4,
        Marketing: this.formParts.controls['marketing'].value,
        Name: this.formParts.controls['name'].value,
        Notes: this.formParts.controls['partdesc'].value,
        SiteID_FK: this.siteid,
        Surname: this.formParts.controls['surname'].value,
        UtmCampaign: this.campaignService.getGoogleTracking(),
        VehicleModel: this.formParts.controls['modeldesc'].value,
        DataSourceID_FK: this.Dealerships[0].DataSourceID_FK
      }

      this.apiservice.post('Enquiries', post).then((x: any) => {
        if (x.EnquiryID_PK !== null) {
          window.location.href = "/parts/thankyou";
        }
      });
    }
  }

  tcs() {
    this.modalService.open('tcs');
  }
}

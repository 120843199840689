import { Injectable } from '@angular/core';
import { GearsApiService } from './gearsapi.service';
import { take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { resolve } from 'path';

@Injectable({
  providedIn: 'root',
})
export class SitesService {
  constructor(private apiService: GearsApiService) {}
  currentSite: any;


  public get() {
    var cache = !environment.dissableCache;
    return new Promise(async (Resolve) => {
      if (cache){
        if (sessionStorage.getItem('CurrentSite') !== null) {
          this.currentSite = JSON.parse(sessionStorage.getItem('CurrentSite') || '{}');
        }
      }
      
      if(this.currentSite){
        Resolve(this.currentSite);
      }
      else{

      var site = window.location.host;

      environment.testsite.forEach((element) => {
        if (element.testurl.includes(window.location.hostname)) {
          site = element.dataurl;
        }
      });
      await this.apiService
      .get('Sites', {
        Domain: site,
        PageSize: 1,
        PageNumber: 1,
        OrderBy: '',
      })
      .then((x: any) => {
        this.currentSite = x.body[0];
        if (this.currentSite.PageLayouts){
          this.currentSite.layout = JSON.parse(this.currentSite.PageLayouts);
          this.currentSite.layout.forEach((data:any) => {
            data.PageLayoutType.forEach((x:any) => {
              try{
                x.Value = JSON.parse(x.Value);
              }
              catch{}
            });
          });
          let url = this.currentSite.layout.filter((s:any) =>{if (s.PageLayoutModuleName == 'features')return s.PageLayoutType} )[0]
                    ?.PageLayoutType.filter((feature:any) => {if (feature.PageLayoutTypeName == "urloverwrite") return feature});
          if (url){
            if (url[0]?.Value){
              site = url[0]?.Value;
            };
          } 
        }
      });
      

      this.apiService
        .get('Sites', {
          Domain: site,
          PageSize: 1,
          PageNumber: 1,
          OrderBy: '',
        })
        .then((x: any) => {
          this.currentSite = x.body[0];

          if (this.currentSite.PageLayouts){
            this.currentSite.layout = JSON.parse(this.currentSite.PageLayouts);
            this.currentSite.layout.forEach((data:any) => {
              data.PageLayoutType.forEach((x:any) => {
                try{
                  x.Value = JSON.parse(x.Value);
              }
              catch{}
              });
            });
            let currentSiteTopnav = this.currentSite.layout.filter((s:any) =>{if (s.PageLayoutModuleName == 'topnav')return s.PageLayoutType} );
            let currentSiteNavbar = this.currentSite.layout.filter((s:any) =>{if (s.PageLayoutModuleName == 'navbar')return s.PageLayoutType} );
            let currentSiteBottomNav = this.currentSite.layout.filter((s:any) =>{if (s.PageLayoutModuleName == 'bottomnav')return s.PageLayoutType} );
            let currentSiteVehicleImage =  this.currentSite.layout.filter((s:any) =>{if (s.PageLayoutModuleName == 'vehicleimages')return s.PageLayoutType} );
            let currentSiteFeature = this.currentSite.layout.filter((s:any) =>{if (s.PageLayoutModuleName == 'features')return s.PageLayoutType} );
            this.currentSite.layoutTonguelogo = currentSiteTopnav[0]?.PageLayoutType.find((data:any) => data.PageLayoutTypeName == 'tonguelogo')
            this.currentSite.layoutBanner = currentSiteTopnav[0]?.PageLayoutType.find((data:any) => data.PageLayoutTypeName == 'banner')
            this.currentSite.sidemenuStyle = currentSiteNavbar[0]?.PageLayoutType.find((data:any) => data.PageLayoutTypeName == 'style')
            this.currentSite.bottomnavBanner = currentSiteBottomNav[0]?.PageLayoutType.find((data:any) => data.PageLayoutTypeName == 'banner')
            this.currentSite.vehicleImages = currentSiteVehicleImage[0]?.PageLayoutType.find((data:any) => data.PageLayoutTypeName == 'fallback')
            this.currentSite.layoutFeatures = [];
            currentSiteFeature[0]?.PageLayoutType.forEach((feature:any) => {this.currentSite.layoutFeatures.push(feature.PageLayoutTypeName)});
            this.currentSite.layoutGlobal = this.currentSite?.layout.filter((s: any) => {
              if (s.PageLayoutModuleName == 'global') return s.PageLayoutType;
            });
          }
          else
            this.currentSite.layout = { topnav: {}, bottomnav: {}, home: [] };

          Promise.all([
          this.apiService
          .getSingle('Containers', this.currentSite.ContainerID_FK)
          ,

          this.apiService
            .get('Dealerships', {
              SiteID_FK: this.currentSite.SiteID_PK,
            })])
            .then((y: any) => {
              if (y[0].body) {
                this.currentSite.container = y[0].body;
              }
              if (y[1].body.length > 1) {
                this.currentSite.dealerSite = false;
              } else {
                this.currentSite.dealerSite = true;
                this.currentSite.dealership = y[1].body[0];
              }
              if (cache) {
                sessionStorage.setItem(
                  'CurrentSite',
                  JSON.stringify(this.currentSite)
                );
              }
              Resolve(this.currentSite);
            });
        });
    }});
  }
}

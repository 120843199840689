<div *ngIf="reviews.length>0">
<div>
    <h4>WHAT OUR CUSTOMERS ARE SAYING</h4>
</div>
<div class="bod">
    <div>
        <p>"{{selected.FeedbackL}}"</p>

    </div>
    <div class="name">
        <h4>{{selected.Author.split('–')[0]}}</h4>
        <h6 *ngIf="site.dealerSite == false">{{selected.DealershipName}}</h6>
        <button aria-label="Prev" class="prev arrow" type="button" (click)="prev()">
            <span class="icon-arrowleft"></span>
        </button>
        <button aria-label="Next" class="next arrow" type="button" (click)="next()">
            <span class="icon-arrowright"></span>
        </button>
    </div>
</div>
</div>
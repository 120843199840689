import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.less']
})
export class ContentComponent {
  @Input() Text = 'base';
  @Input() Image = '';
  constructor() {}
}

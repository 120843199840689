import { Component, OnInit, Input } from '@angular/core';
import { take } from 'rxjs';
import { SitesService } from 'src/app/services/sites.service';
import { GearsApiService } from 'src/app/services/gearsapi.service';
import { VehicleMakesService } from 'src/app/services/vehicle-makes.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less'],
})
export class HomeComponent implements OnInit {
  currentSite: any;
  LabelledBanner: boolean = false;
  Series: any;
  Truck: any;
  constructor(
    private apiservice: GearsApiService,
    private siteService: SitesService,
    private makesservice: VehicleMakesService
  ) {}

  content: any = {};
  banners: any[] = [];
  Makes: any[] = [];
  AllMakes: any[] = [];
  Dealerships: any[] = [];
  DealerRegions: any[] = [];
  Range: any = [];
  siteid: any = 0;
  loadingbanners: boolean = true;
  loading: boolean = true;
  homePagelayout : any;
  homeCardClass: any;

  ngOnInit(): void {
    this.siteService.get().then((x: any) => {
      this.siteid = x.SiteID_PK;
      this.currentSite = x;
      this.homePagelayout = this.currentSite.layout.find((PageLayoutModule:any)=> PageLayoutModule?.PageLayoutModuleName == 'home')
      ?.PageLayoutType.find((PageLayoutType:any) => PageLayoutType.PageLayoutTypeName == 'main')?.Value;
      this.LabelledBanner = this.currentSite.layout.find((PageLayoutModule:any)=> PageLayoutModule?.PageLayoutModuleName == 'home')
      ?.PageLayoutType.find((PageLayoutType:any) => PageLayoutType.PageLayoutTypeName == 'banners')?.Value?.toLowerCase() == 'button' ? true : false;
      
      this.Truck = this.currentSite.layout
        .filter((s:any) =>{if (s.PageLayoutModuleName == 'global')return s.PageLayoutType})[0]?.PageLayoutType
        ?.find((data:any) => data.PageLayoutTypeName == 'truck');

      this.Range = this.currentSite.layout
        .filter((s:any) =>{if (s.PageLayoutModuleName == 'global')return s.PageLayoutType})[0]?.PageLayoutType
        ?.find((data:any) => data.PageLayoutTypeName == 'truck')?.Value.Ranges;

      if (this.homePagelayout?.length > 4){
        this.homeCardClass = this.homePagelayout?.width == 'half'?'halfw':'thirdw' + ' menuitem';
      }
      else if (this.homePagelayout?.length == 4){
        this.homeCardClass = 'col-md-3 col-12';
      }
      else if (this.homePagelayout?.length == 3){
        this.homeCardClass = 'col-md-4 col-12';
      }
      Promise.all([
        this.apiservice.get(
          'Dealerships',
          {
            PageSize: 9999,
            PageNumber: 1,
            OrderBy: '',
            SiteID_FK: this.siteid,
            Active: 1,
            Deleted: 0,
          },
          true
        ),
        this.apiservice.get('Content', {
          SiteID_FK: this.siteid,
          ModuleCode: 'aboutus',
          PageSize: 9999,
          PageNumber: 1,
          OrderBy: '',
          Active: 1,
          Deleted: 0,
        }),
        this.makesservice.getAll(),
        this.makesservice.getStock(),
        this.apiservice.get('Banners', {
          SiteID_FK: this.siteid,
          Module: 'Home',
          PageSize: 9999,
          PageNumber: 1,
          OrderBy: 'Order.ASC',
          Active: 1,
          Deleted: 0,
          OnlyCurrentBanners: true
        }),
      ]).then((vals: any) => {
        this.Dealerships = vals[0].body;
        this.Dealerships.forEach((d: any) => {
          if (!this.DealerRegions.includes(d.Region))
            this.DealerRegions.push(d.Region);
        });
        this.content = vals[1].body[0];
        this.AllMakes = vals[2];
        this.Makes = vals[3];
        this.apiservice.get('VehicleSeries', {
          VehicleMakeID_FK: this.Makes[0]?.VehicleMakeID_PK,
          PageSize: 9999,
          PageNumber: 1,
          OrderBy: 'Name.ASC',
          Active: 1,
          Deleted: 0,
        }).then((series:any)=>{
          this.Series = series.body;
        });
        this.banners = vals[4].body;
        this.loadingbanners = false;
        this.loading = false;
      });
    });
  }
  goParts(selectedDealer: any) {
    if (selectedDealer !== 'null') {
      window.location.href = '/parts?dealership=' + selectedDealer;
    } else {
      window.location.href = '/parts';
    }
  }

  goService(selectedDealer: any) {
    if (selectedDealer !== 'null') {
      var dealer = this.Dealerships.find((x:any)=>x.DealershipID_PK == selectedDealer)
      if (dealer.ServiceOnlineLink) {
        window.open(dealer.ServiceOnlineLink, "_blank");
        window.location.href = "/";
      } else {
        window.location.href = '/service?dealership='+selectedDealer;
      }
    } else {
      window.location.href = '/service';
    }
  }
  goSell(selectedMake: any) {
    if (selectedMake !== 'null') {
      window.location.href = '/sell?make=' + selectedMake;
    } else {
      window.location.href = '/sell';
    }
  }

  goBuy(selectedMake: any, isSeries = false) {
    let path = '';
    if (isSeries == false){
      path = '/buy?make=';
    } else if (isSeries == true){
      path = '/buy?series=';
    }
    if (this.Range !== null && this.Range?.length !== 0 && this.Range !== undefined) {
      window.location.href = selectedMake;
    }
    else if (selectedMake !== 'null') {
      window.location.href = path + selectedMake;
    } else {
      window.location.href = '/buy';
    }
  }

  goSpecial(selectedDealer: any){
    if (selectedDealer !== 'null') {
      window.location.href = '/buy?type=specials&dealership='+selectedDealer;
    } else {
      window.location.href = '/buy?type=specials';
    }
  }

  goCommercials(){
    window.location.href = '/buy?type=commercials';
  }
  
  regionDealers(region: any) {
    return this.Dealerships.filter((x: any) => x.Region == region);
  }
}

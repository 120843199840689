import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ElementRef, Input } from '@angular/core';
import { GearsApiService } from 'src/app/services/gearsapi.service';
import { ModalService } from '../modalservice.service';

@Component({
  selector: 'app-tcs',
  templateUrl: './tcs.component.html',
  styleUrls: ['./tcs.component.less']
})
export class TcsComponent implements OnInit {
  @Input() id?: string;
  isOpen = false;
  private element: any;
  Data: any;
  Regions: any = [];
  Titles: any = [];
  constructor(@Inject(PLATFORM_ID) private platformId: Object,private modalService: ModalService, private el: ElementRef, private apiservice: GearsApiService) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.modalService.add(this);
      document.body.appendChild(this.element);



      this.element.addEventListener('click', (el: any) => {
        if (el.target.className === 'enquiremodal') { 
          this.close();
        }
      });
    }
  }

  ngOnDestroy() {
    this.element.remove();
  }

  open(data: any = null) {
    if (isPlatformBrowser(this.platformId)) {
      this.element.style.display = 'block';
      document.body.classList.add('jw-modal-open');
      this.isOpen = true;
      this.Data = data;
    }
  }

  
  close() {
    if (isPlatformBrowser(this.platformId)) {
      this.element.style.display = 'none';
      document.body.classList.remove('jw-modal-open');
      this.isOpen = false;
    }
  }

}

import { Component, Input, NgModule, OnInit,ElementRef } from '@angular/core';
import { environment } from 'src/environments/environment';

import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.less']
})
export class SliderComponent implements OnInit {
  
  constructor(private elementRef: ElementRef) { }
  public current = 0;

  @Input() public images:Array<any> = [];
  @Input() activeIndex:number = 0;
  @Input() public autoScroll:Boolean = true;
  @Input() public id:string = '';
  @Input() carouselStyling:string = '';
  @Input() labelBanner: boolean = false;
 
  singleImage:any = false;

  ngOnInit() {
   
    if(this.images?.length == 1){this.singleImage = true;}
    if(!this.labelBanner){
      setTimeout(() => {
        const myCarousel = document.querySelector('#CarouselControlNext') as HTMLElement;
        myCarousel?.click();
      }, 5000);
    }
  }
  imgadd(url: string) {
    if (!url.includes('http')) {
      return environment.storageurl + url;
    }
    else {
      return url;
    }

  }

  currentSlide(index : number) {
    this.activeIndex = index;
  }

  next() {
    if (this.activeIndex !== this.images.length - 1) {
      this.activeIndex++;
    }
    else{
      this.activeIndex = 0
    }
  }

  prev() {
    if (this.activeIndex !== 0) {
      this.activeIndex--;
    }
    else{
      this.activeIndex = this.images.length - 1;
    }
  }

  indicatorButton(e:number) {
    let activeClasses = this.elementRef.nativeElement.querySelectorAll('#carouselIndicators'+this.id)
    if (!activeClasses[e]?.classList?.contains('active')){
      activeClasses.forEach(function (activeClass:any){
        if (activeClass?.classList?.contains('active')){
          activeClass.classList.remove('active');
        }
      })
    }
    this.activeIndex = e;
  }
}
@NgModule({
  declarations: [
    SliderComponent,
  ],
  imports:[
    CommonModule,
  ],
  exports: [
    SliderComponent
  ]
})
export class SliderModule { }
<div class="col-12 banner">
    <app-slider [images]="banners" *ngIf="banners.length > 0"></app-slider>
</div>

<div class="container news-feed-list">
    <!--<h2>Check out our latest vehicle insights, features, tips, and advice.</h2>-->
    <div class="npad" *ngIf="getArticleCounts() > 0">
        <div class="row">
            <h3 ngClass="pageModule == 'home' : text-center ? ''">NEWS</h3>
        </div>
        <div class="row">
            <div class="col-12 col-md-4 item homeNewsItem" *ngFor="let v of getArticles()">
                <div class="card">
                    <a class="image card-img-top" href="news/{{v.ArticleID_PK}}/{{v.Title}}">

                        <img loading="lazy" *ngIf="v.ImgFilename !== null && v.ImgFilename && v.ImgFilename != 'N/A'"
                            src="{{imgadd(v.ImgFilename)}}" alt="">
                        <img loading="lazy" *ngIf="v.ImgFilename == null || !v.ImgFilename || v.ImgFilename == 'N/A'"
                            src="{{currentSite.vehicleImages.Value}}" alt=""></a>
                    <div style="border-top: lightgray solid 1px; margin: 0 15px;"></div>
                    <div class="card-body">
                        <div class="bodyblock">
                            <div class="row name">
                                <h6>{{v.Title}}</h6>
                            </div>
                            <p>{{v.Author}}</p>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col-xs-12">
                                <a href="news/{{v.ArticleID_PK}}/{{v.Title}}"><button class="btn-primary"><span
                                            class="icon-news"></span>Read More</button></a>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngFor="let category of CatNames">
        <div *ngIf="getCategoryArticleCount(category)>0">
            <div class="row">
                <h3>{{category}}</h3>
            </div>
            <div class="row">
                <div class="col-12 col-md-4 item" *ngFor="let v of getCategoryArticles(category)">
                    <div class="card">
                        <a class="image card-img-top" href="news/{{v.ArticleID_PK}}/{{v.Title.replaceAll('/', '')}}">

                            <img loading="lazy" *ngIf="v.ImgFilename !== null && v.ImgFilename && v.ImgFilename != 'N/A'"
                                src="{{imgadd(v.ImgFilename)}}" alt="">
                            <img loading="lazy" *ngIf="v.ImgFilename == null || !v.ImgFilename || v.ImgFilename == 'N/A'"
                                src="{{currentSite.vehicleImages.Value}}" alt=""></a>
                        <div class="card-body">
                            <div class="bodyblock">
                                <div class="row name">
                                    <h6>{{v.Title}}</h6>
                                </div>
                                <p>{{v.Author}}</p>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="row">
                                <div class="col-xs-12">
                                    <a href="news/{{v.ArticleID_PK}}/{{v.Title.replaceAll('/', '')}}"><button class="btn-primary"><span
                                                class="icon-news"></span>Read More</button></a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-xs-12 col-sm-6 col-md-4 news-feed-item"
                *ngFor="let article of getCategoryArticles(category.ArticleCategoryID_PK)">
                <div class="panel panel-default clickable animated zoomIn"
                    (click)="viewArticle(article.ArticleID_PK)">
                    <div class="panel-body">
                        <div class="text-center img-container">
                            <img loading="lazy" loading="lazy"
                                *ngIf="article.ImgFilename != 'https://Gearscdn.azureedge.net/gears-live/Articles/N/A'"
                                src="{{article.ImgFilename}}" alt="{{article.Title}}" />
                            <img loading="lazy" loading="lazy"
                                *ngIf="article.ImgFilename == 'https://Gearscdn.azureedge.net/gears-live/Articles/N/A'"
                                src="templates/mccarthytoyota/img/news-placeholder.webp" alt="{{article.Title}}" />
                        </div>
                        <div class="detail">
                            <h4>{{article.Title}}</h4>
                            <button type="button" class="btn btn-primary pull-right">Read More</button>
                        </div>
                    </div>
                </div>
            </div>-->
            <div class="col-xs-12 text-right" *ngIf="getCategoryArticleCount(category) > 3">
                <a *ngIf="NewsReadMoreDestination" href="{{NewsReadMoreDestination}}" class="btn btn-secondary"
                    target="_blank">
                    <span class="glyphicon glyphicon-chevron-right"></span>
                    More {{category}} Articles
                </a>
                <a *ngIf="!NewsReadMoreDestination" href="https://www.toyota.co.za/discover/news"
                    class="btn btn-secondary" target="_blank">
                    <span class="glyphicon glyphicon-chevron-right"></span>
                    More {{category}} Articles
                </a>
            </div>
        </div>
    </div>
</div>
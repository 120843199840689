<div *ngIf="isOpen" class="financemodal">

        <div class="card">
                <div class="card-header">
                        Finance Calculator
                        <a (click)="close()">
                                <p class="close"><span class="icon-cross"></span></p>
                        </a>
                </div>

                <div class="card-body " *ngIf="this.price">
                        <form [formGroup]="financeForm">
                                <div class="selectbox"> 
                                        <div class="flex">
                                                <div class="tag" [ngClass]="this.currency.length > 1 ? 'longCurrency' : ''"><p>{{this.currency}}</p></div>
                                                <div class="tagged">
                                                        <div class="select-radius">
                                                                <p>Vehicle Price</p>
                                                                <input class="col-12" disabled type="text"
                                                                        value="{{this.currency}}{{this.price | number:'1.0-0'}}">
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                                <div class="selectbox">
                                        <div class="select-radius">
                                                <input class="col-12" disabled type="text"
                                                value="Deposit: {{this.currency}}{{this.price * (deposit/100) | number:'1.0-0'}}">
                                        </div>
                                </div>
                                <div class="buttrow">
                                        <div class="butt"><button (click)="dep(0)"
                                                        [ngClass]="this.deposit == 0?'btn-primary':'btn-tertiary'">0%</button>
                                        </div>
                                        <div class="butt"><button (click)="dep(5)"
                                                        [ngClass]="this.deposit == 5?'btn-primary':'btn-tertiary'">5%</button>
                                        </div>
                                        <div class="butt"><button (click)="dep(10)"
                                                        [ngClass]="this.deposit == 10?'btn-primary':'btn-tertiary'">10%</button>
                                        </div>
                                        <div class="butt"><button (click)="dep(15)"
                                                        [ngClass]="this.deposit == 15?'btn-primary':'btn-tertiary'">15%</button>
                                        </div>
                                        <div class="butt"><button (click)="dep(25)"
                                                        [ngClass]="this.deposit == 25?'btn-primary':'btn-tertiary'">25%</button>
                                        </div>
                                </div>
                                <h4>Months</h4>
                                <div class="buttrow">
                                        <div class="butt"><button (click)="mon(36)"
                                                        [ngClass]="this.months == 36?'btn-primary':'btn-tertiary'">36</button>
                                        </div>
                                        <div class="butt"><button (click)="mon(48)"
                                                        [ngClass]="this.months == 48?'btn-primary':'btn-tertiary'">48</button>
                                        </div>
                                        <div class="butt"><button (click)="mon(60)"
                                                        [ngClass]="this.months == 60?'btn-primary':'btn-tertiary'">60</button>
                                        </div>
                                        <div class="butt"><button (click)="mon(66)"
                                                        [ngClass]="this.months == 66?'btn-primary':'btn-tertiary'">66</button>
                                        </div>
                                        <div class="butt"><button (click)="mon(72)"
                                                        [ngClass]="this.months == 72?'btn-primary':'btn-tertiary'">72</button>
                                        </div>
                                </div>
                                <div class="selectbox">
                                        <div class="flex">
                                                <div class="tag" [ngClass]="this.currency.length > 1 ? 'longCurrency' : ''"><p class="percent">%</p></div>
                                                <div class="tagged">
                                                        <div class="select-radius">
                                                                <p>Interest Rate</p><input formControlName="interst"
                                                                class="col-12" type="text" value="{{interst}}">
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                                <div class="selectbox">
                                        <div class="select-radius">
                                                <input class="col-12" disabled type="text"
                                                value="Balloon: {{this.currency}}{{this.price * (balloon/100) | number:'1.0-0'}}">
                                        </div>
                                </div>
                                <div class="buttrow">
                                        <div class="butt"><button (click)="bal(0)"
                                                        [ngClass]="this.balloon == 0?'btn-primary':'btn-tertiary'">0%</button>
                                        </div>
                                        <div class="butt"><button (click)="bal(5)"
                                                        [ngClass]="this.balloon == 5?'btn-primary':'btn-tertiary'">5%</button>
                                        </div>
                                        <div class="butt"><button (click)="bal(10)"
                                                        [ngClass]="this.balloon == 10?'btn-primary':'btn-tertiary'">10%</button>
                                        </div>
                                        <div class="butt"><button (click)="bal(20)"
                                                        [ngClass]="this.balloon == 20?'btn-primary':'btn-tertiary'">20%</button>
                                        </div>
                                        <div class="butt"><button (click)="bal(30)"
                                                        [ngClass]="this.balloon == 30?'btn-primary':'btn-tertiary'">30%</button>
                                        </div>
                                </div>
                                <div class="calcs">
                                        <div class="row textrow">
                                                <p class="col-6">Principal Amount</p>
                                                <p class="col-6 vals">{{this.currency}}{{this.price - (this.price * (deposit/100)) |
                                                        number:'1.0-0'}}</p>
                                        </div>
                                        <div class="row textrow">
                                                <p class="col-6">Finance Amount</p>
                                                <p class="col-6 vals">{{this.currency}}{{monthlyPayment() * this.months |
                                                        number:'1.0-0'}}</p>
                                        </div>
                                        <div class="row textrow">
                                                <p class="col-6">Monthly Payment</p>
                                                <h5 class="col-6 vals">{{this.currency}}{{monthlyPayment() | number:'1.0-0'}}</h5>
                                        </div>
                                </div>
                                <p class="tcs">*Terms and Conditions Apply<br />
                                        Estimated Monthly Instalment & Total Finance Amount is an Estimate and Subject
                                        to Finance Approval with your Bank. McCarthy (Pty)
                                        Ltd is an authorized Financial Services Provider. FSP6852.</p>

                                <a (click)="apply()"><button class="btn-primary">Apply for
                                                Finance</button></a>
                        </form>
                </div>
        </div>
</div>
<div class="container pb-5">
    <h2 class="text-center mb-3" id="campagin">VW Service Booking</h2>
    <div class="row">
        <div class="col-lg-3">
            <h3>Exclusive service offer.</h3>
            <p>
                Welcome to our exclusive service offer waiting room where you can get 100% off the labour cost for your next service.
            </p>
            <p>
                We will be opening the qualifying service slots at our various dealerships, and whoever gets in first gets the offer.
            </p>
            <p>
                Every five minutes, a customer will be randomly selected and granted access to our service booking platform.
            </p>
            <p>
                While you wait, why not check out the items in our shops?
            </p>
            <p>
                <strong>Thank you for your patience.<br>We wish you the best of luck!</strong><br>
            </p>
            <p><a href="#">Ts &amp; Cs apply.</a></p>
        </div>
        <div class="col-lg-9">
            <div class="bg-dark text-center text-white p-3 mb-3">
                <h4>Number of slots still available</h4>
                <h3 class="slot-count"></h3>
                <div class="selectbox border-top-0 border-start-0 border-end-0 rounded-0 short">
                    <div class="select-radius border-0">
                        <p>Select a dealership</p>
                        <select class="" name="dealershipid" id="dealershipid" style="color: white !important; text-align: center;" [(ngModel)]="selectedDealershipID">
                            <option class="text-dark" [value]="0" selected hidden>--Select a dealership--</option>
                            <option class="text-dark" *ngFor="let dealership of Dealerships" value="{{dealership.DealershipID_PK}}">{{dealership.Name}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="bg-dark text-center text-white p-3">
                <div class="row">
                    <div class="col-2 align-content-center"><button class="cursor-pointer btn btn-dark rounded-circle" [disabled]="calendarIndex == 0" (click)="changeMonth(-1)"><span class="icon-arrowleft fs-1"></span></button></div>
                    <div class="col-8">
                        <h4>Service dates available</h4>
                        <p *ngIf="calendar_data[calendarIndex]">{{monthNames[calendar_data[calendarIndex].month-1]}} {{calendar_data[calendarIndex].year}}</p>
                    </div>
                    <div class="col-2 align-content-center"><button class="cursor-pointer btn btn-dark rounded-circle" [disabled]="calendarIndex == calendar_data.length-1" (click)="changeMonth(+1)"><span class="icon-arrowright fs-1"></span></button></div>
                </div>
                <table class="table table-bordered table-dark table-responsive table-layout-fixed mt-3">
                    <thead>
                        <tr>
                            <th scope="col" class="text-truncate">Su</th>
                            <th scope="col" class="text-truncate">Mo</th>
                            <th scope="col" class="text-truncate">Tu</th>
                            <th scope="col" class="text-truncate">We</th>
                            <th scope="col" class="text-truncate">Th</th>
                            <th scope="col" class="text-truncate">Fr</th>
                            <th scope="col" class="text-truncate">Sa</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="calendar_data && calendar_data.length > 0">
                        <tr scope="row" *ngFor="let week of displayCalendar">
                            <td *ngFor="let day of week"
                            [ngClass]="{'calenda-hover-active cursor-pointer text-dark bg-light p-0 align-content-center':isActiveDate(calendar_data[calendarIndex].year,calendar_data[calendarIndex].month,day)}">
                                <span class="py-2 d-block" [ngClass]="{'text-white':!isActiveDate(calendar_data[calendarIndex].year,calendar_data[calendarIndex].month,day)}" (click)="goToService(calendar_data[calendarIndex].year,calendar_data[calendarIndex].month,day)">{{day}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <a href="https://shopvwonline.co.za/" target="_blank"><img class="mt-3 w-100" src="https://gearsapi.elianceprelive.co.za/Files?dir=ContentImages&amp;filename=1729856365581_content" alt="Uploaded Image"></a>
    </div>
</div>
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GearsApiService } from 'src/app/services/gearsapi.service';
import { SitesService } from 'src/app/services/sites.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-newsdetail',
  templateUrl: './newsdetail.component.html',
  styleUrls: ['./newsdetail.component.less']
})
export class NewsdetailComponent implements OnInit{
 constructor(private apiService:GearsApiService, private route:ActivatedRoute, private location: Location, private siteService: SitesService,){}
 article:any;
 sections:any;
 modelId!:string;
 CurrentURL:string = window.location.href;
 FacebookAppId:string = environment.facebookAppId;
 thislocation:any='';
 currentSite: any;
 imgadd(url: string) {
  if (!url.includes('http')) {
    return environment.storageurl + 'Articles/' + url;
  }
  else {
    return url;
  }

}
 back(): void {
  this.location.back();
}
  ngOnInit(): void {
    this.siteService.get().then((x: any) => {this.currentSite = x;});
    this.thislocation = window.location.href;
    this.modelId = this.route.snapshot.paramMap.get('id')!;
    this.apiService.getSingle('Articles',this.modelId).then((x: any) => {
      this.article = x.body;
      this.apiService.get('ArticleSections',{ArticleID_FK:x.body.ArticleID_PK}).then((y:any)=>{
        this.sections = y.body;
      });
    });
  }
}

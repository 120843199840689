import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { GearsApiService } from './gearsapi.service';

@Injectable({
  providedIn: 'root'
})
export class TaskServiceService {

  constructor(private title:Title, private meta:Meta, private apiService:GearsApiService) { }
  navbarbutton: any;

public titleUpdate(module:any, site:any){
  if (module && site) {
    this.apiService
      .get('StateMeta', {
        SiteID_FK: site,
        Module: module,
        Active: 1,
        Deleted: 0,
        OrderBy: '',
        PageSize: 1,
        PageNumber: 1,
      })
      .then((stateMeta: any) => {
        if (stateMeta.body.length > 0) {
          stateMeta = stateMeta.body[0];
          this.title.setTitle(stateMeta.Title || module);
          this.meta.updateTag({
            name: 'keywords',
            content: stateMeta.Keywords,
          });
          this.meta.updateTag({
            name: 'description',
            content: stateMeta.Description,
          });
        }
      });
  }
}

  public navUpdate(input="") {
    if(input!="") {
      this.navbarbutton = input;
    }
    else {
      switch(true){
        case window.location.pathname?.includes('/dealership'):
          this.navbarbutton = "contactus";
          break;
        case window.location.pathname?.includes('/parts'):
          this.navbarbutton = "parts";
          break;
        case window.location.pathname?.includes('/service'):
          this.navbarbutton = "service";
          break;
        case window.location.pathname?.includes('/buy'): {
          switch(true) {
            case window.location.search?.includes('type=new') || window.location.pathname?.includes('/new'):
              this.navbarbutton = "new";
              break;
            case window.location.search?.includes('type=used') || window.location.pathname?.includes('/used'):
              this.navbarbutton = "used";
              break;
            case window.location.search?.includes('type=demo') || window.location.pathname?.includes('/demo'):
              this.navbarbutton = "demo";
              break;
            case window.location.search?.includes('type=commercial') || window.location.pathname?.includes('/commercial'):
              this.navbarbutton = "commercial";
              break;
            case window.location.search?.includes('type=special') || window.location.pathname?.includes('/special'):
              this.navbarbutton = "specials";
              break;
            default:
              this.navbarbutton = "";
              break;
          }
          break;
        }
        default:
          this.navbarbutton = "home";
          break;
      }
    }
  }
}

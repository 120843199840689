import { Component, Input, NgModule, OnInit } from '@angular/core';
import { CommonModule} from '@angular/common'

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.less']
})
export class ReviewsComponent implements OnInit {

  constructor() { }
  @Input() reviewscore!:number;
  @Input() reviewnumber!:number;
  ngOnInit(): void {
  }

}

@NgModule({
  declarations: [
    ReviewsComponent,
  ],
  imports:[
    CommonModule
  ],
  exports: [
    ReviewsComponent
  ]
})
export class ReviewsModule { }

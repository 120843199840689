import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GearsApiService } from 'src/app/services/gearsapi.service';
import { SitesService } from 'src/app/services/sites.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.less'],
})
export class ThankyouComponent implements OnInit {
  constructor(
    private siteService: SitesService,
    private apiService: GearsApiService,
    private route: ActivatedRoute
  ) {}
  banners: any[] = [];
  type!: any;
  messages: any = {
    parts: {
      head: 'Your parts enquiry has been sent.',
      mess: 'We will be in contact with you shortly.',
    },
    service: {
      head: 'Your service enquiry has been sent.',
      mess: 'One of our friendly service advisors will be in contact with you shortly.',
    },
    enquire: {
      head: 'Your enquiry has been sent.',
      mess: 'One of our friendly sales executives will be in contact with you shortly.',
    },
    special: {
      head: 'Your special enquiry has been sent.',
      mess: 'One of our friendly sales executives will be in contact with you shortly.',
    },
    specials: {
      head: 'Your special enquiry has been sent.',
      mess: 'One of our friendly sales executives will be in contact with you shortly.',
    },
    callback: {
      head: '',
      mess: 'One of our friendly sales executives will be in contact with you shortly.',
    },
    alerts: {
      head: 'Your alert has been created.',
      mess: 'A verification email will be sent to you shortly.',
    },
    verifiedalerts: {
      head: 'Your alert has been Verified.',
      mess: 'An alert email will be sent.',
    },
    optoutalert: {
      head: 'Your have alert has been removed.',
      mess: '',
    },
    new: {
      head: 'Your new vehicle enquiry has been sent. ',
      mess: 'One of our friendly sales executives will be in contact with you shortly.',
    },
    used: {
      head: 'Your used vehicle enquiry has been sent. ',
      mess: 'One of our friendly sales executives will be in contact with you shortly.',
    },
    demo: {
      head: 'Your demo vehicle enquiry has been sent.',
      mess: 'One of our friendly sales executives will be in contact with you shortly.',
    },
  };
  getmessage(type: any) {
    return this.messages[type] ? this.messages[type] : this.messages.enquire;
  }
  ngOnInit(): void {
    this.type = this.route.snapshot.paramMap.get('type')!;
    this.siteService.get().then((s: any) => {
      this.apiService
        .get('Banners', {
          SiteID_FK: s.SiteID_PK,
          Module: this.type || 'home',
          PageSize: 9999,
          PageNumber: 1,
          OrderBy: '',
        })
        .then((x: any) => {
          this.banners = x.body;
        });
    });
    setTimeout(
      () => {
        
          
          window.dataLayer.push(
            JSON.parse(sessionStorage.getItem('GAData') || '')
          );
        if (sessionStorage.getItem('GAData')) {
          sessionStorage.removeItem('GAData');
          
        }
      },
      500
    );
  }
}

<div id="main" class="row main">
    <div *ngIf="!loading && this.banners.length>0" class="col-12 banner">
        <app-slider [images]="this.banners"></app-slider>
    </div>
    <div class="col-10 offset-1 ">
        <h4>Search for Dealership Name, Address, Region, Contact Number etc.</h4>
        <div class="row searchrow">
            <form [formGroup]="searchForm">
                <input class="col-12 search" formControlName="search" (focus)="searchfocus(true)"
                    (blur)="searchfocus(false)" type="text" placeholder="Search..">
                <ul class="col-12" *ngIf="searchShowing">


                    <li *ngIf="this.Dealers.length > 0 && filteredDealerships().length > 0">
                        <h5>Dealership</h5>
                        <p *ngFor="let dealer of filteredDealerships()"><a
                                href="/dealership/{{dealer.DealershipID_PK}}">{{dealer.Name}}</a></p><br>
                    </li>
                </ul>
            </form>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-4 item" *ngFor="let d of Dealers">
                <a href="/dealership/{{d.DealershipID_PK}}">
                    <div class="card">
                        <div class="imgcontainer">
                        <img loading="lazy" class="card-img-top" *ngIf="d.Image !== null" src="{{d.Image}}" alt="">
                        <img loading="lazy" class="card-img-top" *ngIf="d.Image == null" src="{{currentSite.vehicleImages.Value}}" alt="">
                        </div>
                        <div class="card-body">
                            <h6>{{d.Name}}</h6>
                            <div class="dealerdetails">
                            <a href="tel:{{d.PhoneNumber}}">
                                <p>Tel: {{d.PhoneNumber}}</p>
                            </a>
                            <p>{{d.AddressPhysicalStreet}}</p>
                            <p>{{d.AddressPhysicalCity}}</p>
                        </div>
                        </div>
                        <div class="card-footer">
                            <app-reviews [reviewnumber]="d.GoogleRatingReviews"
                                [reviewscore]="d.GoogleRatingScore"></app-reviews>
                            

                                    <a *ngIf="d.ServiceOnlineLink" target="_blank" href="{{d.ServiceOnlineLink}}"><button class="btn-primary">Book a
                                        Service Online</button></a>
                                <a *ngIf="!d.ServiceOnlineLink" href="/service?dealership={{d.DealershipID_PK}}"><button class="btn-primary">Book a
                                        Service Online</button></a>

                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>
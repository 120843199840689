import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { BottomNavComponent } from './components/bottom-nav/bottom-nav.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { HeaderInterceptor } from './interceptors/http-header.interceptor';
import { ContentComponent } from './components/content/content.component';
import { ImageSliderComponent } from './components/image-slider/image-slider.component';
import { VehicleseriesComponent } from './components/vehicleseries/vehicleseries.component';
import { SliderComponent, SliderModule } from './components/slider/slider.component';
import { DynamicsliderComponent, DynamicsliderModule } from './components/dynamicslider/dynamicslider.component';

import { LatestStockComponent } from './components/latest-stock/latest-stock.component';
import { ReactiveFormsModule }from '@angular/forms';
import { EnquireComponent } from './components/enquire/enquire.component';
import { EnquireComponentModule } from './modules/enquire/enquire.component';
import { PartsComponent } from './components/parts/parts.component';
import { ServiceComponent } from './components/service/service.component';
import { DragScrollModule } from 'ngx-drag-scroll';
import { VehiclesComponent } from './components/vehicles/vehicles.component';
import { FinanceComponent } from './modules/finance/finance.component';
import { DealerinfoComponent } from './components/dealerinfo/dealerinfo.component';
import { DealersComponent } from './components/dealers/dealers.component';
import { ThankyouComponent } from './components/thankyou/thankyou.component';
import { FinanceApplicationComponent } from './components/financeapplication/financeapplication.component';
import { FavouritesComponent } from './components/favourites/favourites.component';
import { PricecompareComponent } from './components/pricecompare/pricecompare.component';
import { CustomerfeedbackComponent } from './components/customerfeedback/customerfeedback.component';
import { AlertsComponent } from './modules/alerts/alerts.component';
import { NewsComponent } from './components/news/news.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { NewsdetailComponent } from './components/news/newsdetail/newsdetail.component';
import { WhyserviceComponent } from './components/whyservice/whyservice.component';
import { TcsComponent } from './modules/tcs/tcs.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ReviewsModule } from './components/reviews/reviews.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { FleetContactUsComponent } from './components/fleetcontactus/fleetcontactus.component';
import { ServicecampaignComponent } from "./components/servicecampaign/servicecampaign.component";


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavBarComponent,
    BottomNavComponent,
    ContentComponent,
    ImageSliderComponent,
    VehicleseriesComponent,
    LatestStockComponent,
   
    EnquireComponentModule,
    PartsComponent,
    ServiceComponent,
    VehiclesComponent,
    FinanceComponent,
    DealerinfoComponent,
    DealersComponent,
    ThankyouComponent,
    FinanceApplicationComponent,

    FleetContactUsComponent,
    
    FavouritesComponent,
    
    PricecompareComponent,
    CustomerfeedbackComponent,
    AlertsComponent,
    NewsComponent,
    NewsdetailComponent,
    WhyserviceComponent,
    
    TcsComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    SliderModule,
    DynamicsliderModule,
    HttpClientModule,
    ReactiveFormsModule,
    DragScrollModule,
    GoogleMapsModule,
    ReviewsModule,
    EnquireComponent,
    MatFormFieldModule, MatInputModule, MatDatepickerModule, MatNativeDateModule, BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: !isDevMode(),
        // Register the ServiceWorker as soon as the application is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000'
    }),
    ServicecampaignComponent
],
  providers: [{provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor,multi : true}, { provide: MAT_DATE_LOCALE, useValue: 'en-ZA' }],
  bootstrap: [AppComponent]
})
export class AppModule { }

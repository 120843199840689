<div *ngIf="!loading && features.length > 0" class="container-fluid featureList g-0" (window:resize)="foo(currentIndex)">
    <div class="heading">
        <h2 class="padding-title"><strong>FEATURES</strong></h2>
    </div>
    <div class="row flex-row flex-nowrap">
        <drag-scroll [snap-offset]="0" #badges [snap-disabled]="true" [drag-scroll-disabled]="true" [drag-disabled]="true">
            <div class="item" drag-scroll-item *ngFor="let f of features; index as i" [ngClass]="{'active': currentIndex == i}">
            <div class="card-features" >
                <div [innerHTML]="f.HTMLValue"></div>
                <!-- <div class="card-img-top image"><a href="{{b.Link}}"><img loading="lazy" class="d-block w-10" [ngClass]="FeatureImgClass"
                            src="{{imgadd(b.ImageURL)}}" alt="{{b.ImageURL}}"></a></div>
                <div class="card-body">
                    <a *ngFor="let but of b.Buttons" href="{{but.URL}}"><button class="btn-primary">{{but.Label}}</button></a>
                    
                </div> -->
            </div>
            </div>
        </drag-scroll>
    </div>
    <div class="horizontal">
        <button *ngIf="features.length > 1" [style.visibility]="(this.currentIndex == 0) ? 'hidden' : ''" aria-label="Prev" class="prev arrow" type="button" (click)="prev()">
            <span class="icon-arrowleft2"></span>
        </button>
        <div style="text-align:center" *ngFor="let dot of features; let i = index">
            <span class="dot" [ngClass]="{'active-dot': currentIndex == i}" (click)="currentSlide(i)"></span>
        </div>
        <button *ngIf="features.length > 1" [style.visibility]="(this.currentIndex == (this.features.length - 1)) ? 'hidden' : ''" aria-label="Next" class="next arrow" type="button" (click)="next()">
            <span class="icon-arrowright2"></span>
        </button>
    </div>
</div>